import { isMobileWidth } from './tools';

export function removeFormErrors() {
  $('.error-msg-form').remove();
  $('.error-msg').remove();
  $('.form-error').remove();
  $('.error').removeClass('error');
  $('div.form-error-wrapper').html('');
  $('div.field-error-wrapper').html('');
}
export function errorStatus() {
  $('body').on('blur', '.error input,.error textarea', function() {
    $(this)
      .closest('.error')
      .removeClass('error');
  });
}

export var appendFormErrors = function(data) {
  if (!data) {
    return;
  }
  let is_mobile = isMobileWidth()

  if ($.fn.stopPulseLoading) {
    $.fn.stopPulseLoading();
  }

  // Allauth uses specific form scheme, different than rest of the app.
  var is_allauth = false;
  if (typeof data.form !== 'undefined') {
    is_allauth = true;
    handleErrors(data.form);
  } else {
    if (!data.form_errors) {
      return;
    }
    handleErrors(data.form_errors);
  }

  function handleErrors(form_or_errors) {
    // Non-field errors from allauth
    if (is_allauth && form_or_errors.errors.length) {
      var $wrapper = $('div.form-error-wrapper');
      $wrapper.html('<div class=\'error-msg-form\'>' + form_or_errors.errors + '</div>');
      return;
    }

    $.each(is_allauth ? form_or_errors.fields : form_or_errors, function(k, v) {
      v = is_allauth ? v.errors : v;
      if (!v.length) {
        return;
      }

      // Non-field errors from the rest of app
      if (k === '__all__') {
        var $wrapper = $('div.form-error-wrapper');
        $wrapper.html('<div class=\'error-msg-form\'>' + v + '</div>');
        return;
      }

      var $elem = $('input[name=' + k + ']');
      if (!$elem.length) {
        $elem = $('textarea[name=' + k + ']');
      }
      var $error_wrapper = $('#' + k + '-error-wrapper');

      if ($elem.length) {
        $elem.addClass('error');

        $elem.on('touchstart.remove-errors focus.remove-errors', function() {
          $(this).removeClass('error');
          $('.' + k + '-error').remove();
          $(this).off('touchstart.remove-errors focus.remove-errors');

          if ($error_wrapper.length && $error_wrapper.hasClass('highlightable')) {
            $error_wrapper.removeClass('error');
          }
        });
      }

      if ($error_wrapper.length) {
        if ($error_wrapper.hasClass('highlightable')) {
          $error_wrapper.addClass('error');
        }
        var error_html =
          '<span class=\'error-msg ' + k + '-error\'>' + v + '</span>';

        if (
          $error_wrapper.is('.js-err-top') ||
          ($error_wrapper.is('.js-err-top-mobile') && is_mobile)
        ) {
          $error_wrapper.prepend(error_html);
        } else {
          $error_wrapper.append(error_html);
        }

        return;
      }

      if (
        typeof $elem.eq(0) === 'undefined' ||
        $elem.eq(0).attr('type') === 'radio'
      ) {
        var $container = $('#id_' + k);
        if ($container.length) {
          $container.prepend(
            '<div class=\'error-msg-form ' + k + '-error\'>' + v + '</div>',
          );
        }
      } else {
        $elem
          .parent()
          .append('<span class=\'error-msg ' + k + '-error\'>' + v + '</span>');
      }
    });
  }

  var $first_error = $('html, body')
    .find(
      '.form-error-wrapper span, .field-error-wrapper span, .error, .error-msg',
    )
    .eq(0);
  pomagamy.scrollToElem($first_error, 0, true);
};

export function toggleLockFormsOnSubmit($wrapper, lock = true) {
  // Disable clicks.
  $wrapper.find('.js-lock-on-submit').prop('disabled', lock);
  // Apply styling, such as cursor: not-allowed, etc.
  $wrapper.find('.js-lock-on-submit').toggleClass('disabled', lock);
}
