import { RAGE_LOCK_CLASS } from './tools';

export function pulseOnLoad(context) {
  const $pulseOnLoad = $('.pulse-on-load', context);

  if ($pulseOnLoad.length) {
    // Check if parent with class 'load-button-wrapper' exists
    if (!$pulseOnLoad.parent().hasClass('load-button-wrapper')) {
      $pulseOnLoad.wrap('<div class="load-button-wrapper"></div>');
    }

    // Check if sibling with 'signal' class exists
    if (!$pulseOnLoad.siblings('em.signal').length) {
      $pulseOnLoad.after('<em class="signal"></em>');
    }

    $pulseOnLoad.on('click.pulseOnLoad', function(e) {
      const $this = $(this);
      // Prevent animation when .pulse-on-load class has been removed
      // or when the element has been locked by antiRageClick.
      if (!$this.hasClass('pulse-on-load') || $this.hasClass(RAGE_LOCK_CLASS)) {
        return;
      }

      if ($this.hasClass('animating')) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }

      $this.addClass('animating');

      $this.fadeTo(300, 0, function() {
        $this.parent().addClass('loading');
      });
    });
    $.fn.stopPulseLoading = function() {
      if ($pulseOnLoad.hasClass('animating')) {
        $pulseOnLoad.fadeTo(300, 1, function() {
          $pulseOnLoad
            .removeClass('disabled animating')
            .parent()
            .removeClass('loading');
        });
      }
    };
  }
}

function initPostalCode($postal_code) {
  $postal_code.attr('maxlength', 6);
  $postal_code.on('keypress', function(e) {
    var $this = $(this),
      value = $this.val(),
      number = /[0-9]/.test(String.fromCharCode(e.which)),
      allowed =
        e.metaKey || // cmd/ctrl
        e.which <= 0 || // arrow keys
        e.which === 8 || // delete key
        number; // numbers
    if (allowed && value.length === 1 && number) {
      $this.val(value + String.fromCharCode(e.which) + '-');
      return false;
    }
    return allowed;
  });
}

export function tabsSwitcher(selector) {
  // SIMPLE TABS OWN PLUGIN
  /* smallest structure html
     <div class="tabs-switcher">
     <div class="switcher">
     <a href="yy" class="select-green bg-danger active">xx</a>
     <a href="yy">xx</a>
     </div>
     </div>
  */
  $(selector)
    .find('a')
    .click(function(e) {
      e.preventDefault();
      var href = $(this).attr('href');
      $(this)
        .siblings()
        .removeClass('bg-danger active');
      $(this).addClass('bg-danger active');
      var $target = $('#' + href);
      $target.siblings().removeClass('active');
      $target.addClass('active');
    });
}
export function init_inputs(context) {
  var $radio = $(':radio', context),
    $checkbox = $(':checkbox', context).not('input.checkboxDefault'),
    $checkboxDefault = $('input.checkboxDefault', context),
    $incrementer = $('.incrementer', context),
    $select = $('select', context),
    $counter = $('.show-counter', context),
    $postal_code = $('#id_postal_code', context),
    $tabs_switcher = $('.popup-tab-switcher', context);

  if ($tabs_switcher.length) {
    tabsSwitcher('.popup-tab-switcher');
  }
  if ($radio.length) {
    $radio.pomagamyRadio();
  }
  if ($checkbox.length) {
    $checkbox.pomagamyCheckbox();
  }
  if ($checkboxDefault.length) {
    $checkboxDefault.pomagamyCheckboxDefault();
  }
  if ($incrementer.length) {
    $incrementer.incrementer();
  }
  if ($counter.length) {
    $counter.pomagamyCounter();
  }

  pulseOnLoad(context);

  $select.filter(':not(.big):not(.form-control)').select({
    css: { icon: 'select-icon icon-strzalka_gora' },
  });

  // Block non-numeric user input on type='number' input fields.
  $("input[type='number'], .js-numbers-only")
    .not('.allow-decimal')
    .on('keypress', function(e) {
      return (
        e.metaKey || // cmd/ctrl
        e.which <= 0 || // arrow keys
        e.which === 8 || // delete key
        /[0-9]/.test(String.fromCharCode(e.which))
      ); // numbers
    });

  function handleCreditCardInputChange(input_elem) {
    // Discovers Credit Card provider (type) by the number put by the user.
    // Discovery is triggered on each character update
    const visa = 'VISA';
    const master = 'MASTERCARD';
    const unknown = 'UNKNOWN';

    const visa_regex = new RegExp('^4[0-9]*');
    const mastercard_regex = new RegExp('^5[1-5][0-9]*');
    const mastercard2_regex = new RegExp('^2[2-7][0-9]*');

    function detectCardType(cc_num) {
      if (visa_regex.test(cc_num)) {
        return visa;
      }
      if (mastercard_regex.test(cc_num) || mastercard2_regex.test(cc_num)) {
        return master;
      }
      return unknown;
    }

    const $card_icons = $('.card-icon');
    $card_icons.removeClass('match no-match'); // Remove previous results

    const card_number = input_elem.inputmask.unmaskedvalue();
    if (card_number) {
      const card_type = detectCardType(card_number);
      $card_icons.each(function() {
        let $icon = $(this);
        if ($icon.hasClass(card_type.toLowerCase())) {
          if (card_type === unknown && card_number.length === 16) {
            // case when full number is put, but it does not fit VISA or MASTERCARD pattern - showing X icon
            $icon.addClass('match');
          } else if (card_type !== unknown) {
            $icon.addClass('match');
          }
        } else {
          $icon.addClass('no-match');
        }
      });
    }
  }

  $.each($('.input-masked'), function(index, elem) {
    const mask = elem.dataset.mask;
    // Setting min/max is needed to avoid locking out typing into field
    // This is a browser behaviour, let's just live with it.
    elem.setAttribute('minlength', mask.length);
    elem.setAttribute('maxlength', mask.length);
    Inputmask({
      mask: mask,
      showMaskOnHover: false,
      onBeforeWrite: function(event, buffer, caretPos, opts) {
        if (elem.id === 'id_credit_card_number' && !event.empty) {
          handleCreditCardInputChange(elem);
        }
      },
    }).mask(elem);
  });

  if ($postal_code.length) {
    initPostalCode($postal_code);
  }
}
