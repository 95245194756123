import './polyfills';
import * as Sentry from '@sentry/browser';
import './plugins/plugins';
import Clipboard from 'clipboard';
import 'slick-carousel';
import 'inputmask';
import Parallax from 'parallax-js';
import ScrollReveal from 'scrollreveal';
import store from 'store';
import storeObservePlugin from 'store/plugins/observe';
import { initReadMore } from './components/read-more';
import { CountUp } from 'countup.js';
import {
  antiRageClick,
  getCookie,
  inputIntValue,
  setCookie,
  RAGE_LOCK_CLASS,
  setTrackingCookie,
} from './components/tools';
import {
  calculateRates,
  hideOddMobilePaymentChannel,
  initHideUserData,
  push_GA_add_event,
  push_GA_details_event,
  recentSupportNotification,
  storeGAid,
  updateDonationValue,
  updateSummaryValues,
} from './components/payment/tools';
import { createSlider, initPledgeButtons } from './components/payment/elements';
import { init_inputs, tabsSwitcher } from './components/input_tools';
import {
  appendFormErrors,
  errorStatus,
  removeFormErrors,
  toggleLockFormsOnSubmit,
} from './components/form_tools';
import { HowToPayOnLoad } from './components/payment/popups';
import { initStripePayments, showGooglePayDialog } from './components/payment/providers/stripe';
import {
  beginApplePaySession,
  cancelApplePaySession,
  createZenTx,
  initZenPayments, toggleOverlay,
  widgetConfigParams,
} from './components/payment/providers/zen';
import {
  initCustomDonationHelperEvents,
  initOnlineSubmitEvent,
  initPaymentChannelsEvents,
  initPledgeEvents,
  initShowAllBanksEvent,
  initTosCheckEvent,
  intIncreasePledgeToMinValue,
} from './components/payment/events';
import { initExp47VariantB, updateVariantBDonationValue } from './components/payment/exp47';

store.addPlugin(storeObservePlugin);

window.store = store;

let sentryDSNFrontend = __SENTRY_DSN_FRONTEND__;

if (sentryDSNFrontend) {
  Sentry.init({
    dsn: sentryDSNFrontend,
    release: __SENTRY_RELEASE__,
  });
}

global.$ = $;
global.jQuery = $;

window.pomagamy = window.pomagamy || {};
window._AutofillCallbackHandler = window._AutofillCallbackHandler || function(){}


const initAsyncImports = () => {
  if (document.querySelector('.crop-image-file-field')) {
    import(
      /* webpackChunkName: "cropper-handle-chunk" */
      './cropper-handle'
    );
  }

  if (document.querySelector('.project-wizard')) {
    import(
      /* webpackChunkName: "story-chunk" */
      './story'
    );
  }

  if (document.querySelector('.project-search-input')) {
    import(
      /* webpackChunkName: "search-input-chunk" */
      './searchInput'
    );
  }

  // if (document.querySelector('.project-carousel')) {
  //   import(
  //     /* webpackChunkName: "slick-carousel-chunk" */
  //     './plugins/slick-carousel'
  //   );
  // }
};

const initWaypoints = () => {
  const container = document.querySelector('.waypoint-container');
  if (!container) return;

  let sectionList = document.querySelectorAll('[id^="waypoint-"]');
  let sections = {};
  let i = 0;

  sectionList.forEach(section => {
    sections[section.id] = section.offsetTop;
  });

  window.onscroll = function() {
    let scrollPosition =
      document.documentElement.scrollTop || document.body.scrollTop;
    const offset = 90;

    for (i in sections) {
      if (sections[i] - offset <= scrollPosition) {
        container.querySelector('.active').classList.remove('active');
        container
          .querySelector('a[href*=' + i + ']')
          .parentElement.classList.add('active');
      }
    }
  };
};

const initPopovers = () => {
  const handleCloseClick = function(e) {
    // Hide popover when clicked on X icon inside.
    // DEVS: catch is empty by design as it throws some random errors in console.
    try {
      const target = $(this).data('popover');
      $body.find(`.js-popover[data-popover=${target}]`).popover('hide');
    } catch {}
  };

  const handleCloseClickOutside = function(event) {
    // Hide popover when clicked outside.
    // DEVS: catch is empty by design as it throws some random errors in console.
    try {
      if (!$(event.target).closest('.js-popover, .popover').length) {
        $('.js-popover').popover('hide');
      }
    } catch {}
  };

  // Initialize popovers
  const $popovers = $('.js-popover');

  $popovers.popover({
    html: true,
    content: function() {
      return $(this)
        .find('.js-popover-content')
        .html();
    },
    container: document.getElementById('bs-limbo'),
    placement: 'bottom',
    constraints: [
      {
        to: 'window',
        attachment: 'together',
        pin: true,
      },
    ],
  });

  $popovers.on('shown.bs.popover', function(e) {
    $body.find('#bs-limbo').on('click touchend', '.js-popover-close', handleCloseClick);
    $(document).on('click touchend', handleCloseClickOutside);
  });

  $popovers.on('hide.bs.popover', function() {
    $(document).off('click touchend', handleCloseClickOutside);
  });
};

const doScrolling = (elementY, duration) => {
  const startingY = window.pageYOffset;
  const diff = elementY - startingY;
  let start;

  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    // Elapsed milliseconds since start of scrolling.
    var time = timestamp - start;
    // Get percent of completion in range [0, 1].
    var percent = Math.min(time / duration, 1);

    window.scrollTo(0, startingY + diff * percent);

    // Proceed with animation as long as we wanted it to.
    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  });
};

function getScrollbarWidth() {
  return window.innerWidth - document.documentElement.clientWidth;
}

let $window = $(window);
let $html = $(document.documentElement);
let $body = $(document.body);
let $page = $('#page');
let $popups = $('#js-popups');
let tabletBreakPoint = 1020;
let mobile = $window.width() < 768;
let has_adblock = false;
let ajax;
let hiddenClass = 'hidden-by-dynamic';
let activeClass = 'open';
let lock_class = 'processing';
let animationSpeed = 300;
let enable_beforeunload_message = false;

// Turn it on by using enable_beforeunload_message = !mobile in initXxx.
let $opened_form;
let opened_form_initial_data;

window.mobileAndTabletcheck = function() {
  var check = false;
  (function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

function getAllImagesDonePromise($images) {
  // Code from https://stackoverflow.com/questions/38542351/jquery-or-javascript-function-after-all-images-loaded

  var d = $.Deferred();
  var imgs = $images;

  // Don't use onload promises on lazy images, it won't work.
  if ($images.filter("[loading='lazy']").length) {
    return d.resolve();
  }

  imgs.one('load.allimages error.allimages', function() {
    // This one completed, remove it
    imgs = imgs.not(this);
    if (imgs.length === 0) {
      // It was the last one, resolve
      d.resolve();
    }
  });

  var complete = imgs.filter(function() {
    return this.complete;
  });
  complete.off('.allimages');
  imgs = imgs.not(complete);
  complete = undefined; // Don't need it anymore

  // If none left, resolve; otherwise wait for events
  if (imgs.length === 0) {
    d.resolve();
  }
  return d.promise();
}

function getUrlParamByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function menuChangeTheme(menu_selector, container_selector, wrapper_selector, ajax_command) {
  var $links = $(menu_selector).find('a'),
    $project_container = $(container_selector),
    animating = false,
    ajax;

  function onClickHandler(e) {
    var $this = $(this),
      url,
      ajax_data = {},
      $project_ul = $project_container.find(wrapper_selector),
      $cards = $project_ul.find('.list-element'),
      $old_read_more_btn = $project_container.find(
        'div.button-group',
      );
    if (!animating && !$this.hasClass('active')) {
      animating = true;

      if (ajax) {
        ajax.abort();
      }

      /*Dirty hack to keep both first page css opacity transition and next pages jQuery fadeIn*/
      $project_container.addClass('opacity-trans');

      $cards.addClass('inactive');

      $links.removeClass('active');

      setTimeout(function() {
        animating = false;
      }, 700);

      url = this.href;
      ajax_data[ajax_command] = true;
      ajax = $.ajax({
        url: url,
        type: 'GET',
        data: ajax_data,
      })
        .done(function(data) {
          var $content = $(data.projects_html),
            $cards = $content.find('.list-element'),
            $new_read_more_btn = $($content[2]),
            $img = $content.find('img');
          var appendContent = function() {
            ajax = null;

            $cards.addClass('inactive');
            $project_ul.find('.list-element').remove();
            $project_ul.append($cards);

            $old_read_more_btn.remove();
            if ($new_read_more_btn.length) {
              $project_container.append($new_read_more_btn);
            }
            $this.addClass('active');

            $('a.read-more-button').pLoader();
            setTimeout(function() {
              $cards.removeClass('inactive');
            }, 0);
          };

          getAllImagesDonePromise($img).then(function() {
            appendContent();
          });
        })
        .fail(function() {
          location.reload();
        });
    }
    e.preventDefault();
  }

  $links.each(function(i) {
    this.index = i;
  });

  /*Dirty hack to keep both first page css opacity transition and next pages jQuery fadeIn*/
  $project_container.addClass('opacity-trans');
  $project_container.on('click', '.read-more', function() {
    $project_container.removeClass('opacity-trans');
  });

  $links.on('click', onClickHandler);
}

function popupOnScroll(targetMobile, targetDesktop) {
  var $target_mobile = $(targetMobile);
  if (!$target_mobile) {
    return;
  }

  function handleMobile() {
    if (!localStorage.getItem('js-popup-exit')) {
      localStorage.setItem('js-popup-exit', JSON.stringify({}));
    }

    var localStoragePopup = JSON.parse(localStorage.getItem('js-popup-exit')),
      scrollPopupDone = false,
      scrollPopupCount = 0,
      scrollPopupLimit = 1,
      scrollReachDescription = false,
      descPosition = $('.box.story').offset().top;

    if (
      localStoragePopup[$target_mobile.data('slug')] &&
      localStoragePopup[$target_mobile.data('slug')].t > Date.now()
    ) {
      scrollPopupDone = true;
    }

    if (!scrollPopupDone) {
      $window.on('scroll', function() {
        if (!scrollReachDescription && $window.scrollTop() >= descPosition) {
          scrollReachDescription = true;
        }

        if (
          scrollReachDescription &&
          $(this).scrollTop() === 0 &&
          !$body.is('.modal-open')
        ) {
          // Show reminder only if there's no other active popup.
          pomagamy.show_popup(targetMobile);

          scrollPopupCount++;
          if (scrollPopupCount >= scrollPopupLimit) {
            scrollPopupDone = true;
            localStoragePopup[$target_mobile.data('slug')] = {
              t: Date.now() + 24 * 60 * 60 * 1000, //1 day = 24h 60min 60sec 1000 milsec
            };
            localStorage.setItem(
              'js-popup-exit',
              JSON.stringify(localStoragePopup),
            );
            $window.unbind('scroll');
          }
        }
      });
    }
  }

  function handleDesktop() {
    var $targetDesktop = $(targetDesktop);
    $targetDesktop.find('.close-icon').on('click', function() {
      $targetDesktop.addClass('closed');
    });
    setTimeout(function() {
      $targetDesktop.removeClass('closed');
    }, 5000);
  }

  if (mobile) {
    handleMobile();
  } else {
    handleDesktop();
  }
}

function checkWindows() {
  var ua = window.navigator.userAgent;

  if (
    navigator.platform.toUpperCase().indexOf('WIN') !== -1 ||
    ua.match(/Windows Phone/i)
  ) {
    $body.addClass('win');
  }

  var old_ie = ua.indexOf('MSIE '),
    new_ie = ua.indexOf('Trident/');

  if (old_ie > -1 || new_ie > -1) {
    $body.addClass('win-ie');
  }
}

function checkMac() {
  var ua = window.navigator.userAgent;

  var isMacLike = !!navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i);

  if (isMacLike) {
    $body.addClass('mac');
  }
  if (ua.indexOf('iPhone') !== -1) {
    $body.addClass('iPhone');
  }

  if (ua.indexOf('Mac OS X') !== -1) {
    $body.addClass('osx');
  }

  if (ua.indexOf('MessengerForiOS') !== -1) {
    return; // Don't use osx10_3_up for messenger. I know, it's iOS world is borderline crazy.
  }

  var re = /OS\s([0-9_]+)\slike\sMac\sOS\sX/g,
    match = re.exec(ua),
    os_version;
  if (!match) {
    return;
  } else {
    os_version = match[1];
    if (!os_version.length) {
      return;
    }
  }
  os_version = os_version.split('_').map(Number);
  if ((os_version[0] === 10 && os_version[1] >= 3) || os_version[0] > 10) {
    $body.addClass('osx10_3_up');
  }
}

function checkLinux() {
  var isLinuxLike = !!navigator.platform.match(/(Linux)/i);

  if (isLinuxLike) {
    $body.addClass('linux');
  }
}

function checkUnsupportedBrowsers() {
  // Auto scroll on input focus.
  if (/Android [4-7]/.test(navigator.appVersion)) {
    if (!Element.prototype.scrollIntoViewIfNeeded) {
      Element.prototype.scrollIntoViewIfNeeded = function(centerIfNeeded) {
        centerIfNeeded = arguments.length === 0 ? true : !!centerIfNeeded;

        var parent = this.parentNode,
          parentComputedStyle = window.getComputedStyle(parent, null),
          parentBorderTopWidth = parseInt(
            parentComputedStyle.getPropertyValue('border-top-width'),
          ),
          parentBorderLeftWidth = parseInt(
            parentComputedStyle.getPropertyValue('border-left-width'),
          ),
          overTop = this.offsetTop - parent.offsetTop < parent.scrollTop,
          overBottom =
            this.offsetTop -
              parent.offsetTop +
              this.clientHeight -
              parentBorderTopWidth >
            parent.scrollTop + parent.clientHeight,
          overLeft = this.offsetLeft - parent.offsetLeft < parent.scrollLeft,
          overRight =
            this.offsetLeft -
              parent.offsetLeft +
              this.clientWidth -
              parentBorderLeftWidth >
            parent.scrollLeft + parent.clientWidth,
          alignWithTop = overTop && !overBottom;

        if ((overTop || overBottom) && centerIfNeeded) {
          parent.scrollTop =
            this.offsetTop -
            parent.offsetTop -
            parent.clientHeight / 2 -
            parentBorderTopWidth +
            this.clientHeight / 2;
        }

        if ((overLeft || overRight) && centerIfNeeded) {
          parent.scrollLeft =
            this.offsetLeft -
            parent.offsetLeft -
            parent.clientWidth / 2 -
            parentBorderLeftWidth +
            this.clientWidth / 2;
        }

        if (
          (overTop || overBottom || overLeft || overRight) &&
          !centerIfNeeded
        ) {
          this.scrollIntoView(alignWithTop);
        }
      };
    }

    window.addEventListener('resize', function() {
      if (
        document.activeElement.tagName === 'INPUT' ||
        document.activeElement.tagName === 'TEXTAREA'
      ) {
        window.setTimeout(function() {
          document.activeElement.scrollIntoViewIfNeeded();
        }, 0);
      }
    });
  }

  var nua = navigator.userAgent;
  if (nua.indexOf('Android ') > -1) {
    $body.addClass('android');
  }

  var is_old_android =
      nua.indexOf('Mozilla/5.0') > -1 &&
      nua.indexOf('Android ') > -1 &&
      nua.indexOf('AppleWebKit') > -1 &&
      nua.indexOf('Chrome') <= -1 &&
      nua.indexOf('IEMobile') <= -1,
    is_old_ie = $html.is('.lt-ie10'),
    is_old_opera = nua.indexOf('Opera') > -1 && nua.indexOf('Presto') > -1; // Opera < 15 had Presto rendering engine.

  // Show helpful info if old unsupported browser is encountered.
  if (is_old_android || is_old_ie || is_old_opera) {
    window.location.href =
      'https://browser-update.org/pl/update.html?force_outdated=true';
  }
}

function checkIE() {
  return (
    window.navigator.userAgent.indexOf('MSIE') > 0 ||
    !!navigator.userAgent.match(/Trident\/7\./)
  );
}

function checkFacebookApp() {
  var ua = navigator.userAgent || navigator.vendor || window.opera;
  if (ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1) {
    $body.addClass('fb-browser');
  }
}

var initCookiesBox = function() {
  var key = 'accept_cookies',
    enabled_class = 'active';

  if (getCookie(key) !== 'true') {
    var $cookies_box = $page.find('#cookies');
    if (!$cookies_box.length) {
      return;
    }

    $cookies_box.addClass(enabled_class);
    // Set cookie on first visit.
    setCookie(key, 'true', 365);

    $cookies_box.find('.btn').on('click', function(e) {
      $cookies_box.toggleClass('active');
      setTimeout(function() {
        $cookies_box.remove();
      }, 600);
      e.preventDefault();
    });
  }
};
var initPPBox = function() {
  // Handles Privacy Policy change box.
  var key = 'accept_pp',
    enabled_class = 'active';
  if (getCookie(key) !== 'true') {
    var $box = $page.find('#pp-info');
    if (!$box.length) {
      return;
    }
    $box.addClass(enabled_class);
    // Set cookie on first visit.

    $box.find('.btn').on('click', function(e) {
      $box.removeClass(enabled_class);
      setCookie(key, 'true', 365);
      setTimeout(function() {
        $box.remove();
      }, 600);
      e.preventDefault();
    });
  }
};

var checkAdblock = function() {
  var $bait = $('div#t-ab');
  if (!$bait.length) {
    return;
  }
  if ($bait.filter(':visible').length === 0) {
    has_adblock = true;
  }
  if (has_adblock && $page.is('.profile')) {
    $('#fb-page-section').remove();
  }
};

pomagamy.substitute = function(str, sub) {
  return str.replace(/{{(.+?)}}/g, function(match, name) {
    return name in sub ? sub[name] : match;
  });
};

var isScrolledIntoView = function($elem, extra_offset, is_error) {
  // Is $elem scrolled into a first 3rd for the current view?
  var $window = $(window),
    view_top = $window.scrollTop(),
    elem_top = $elem.offset().top - extra_offset,
    window_section = ($window.height() / 3) * (is_error ? 2 : 1);

  // console.log(`View top: ${view_top}\nElem top raw: ${$elem.offset().top}, elem top adj: ${elem_top}\nElem_top - View_top = ${elem_top-view_top}\nWindow section: ${window_section}` );
  // console.log(`view_top < elem_top (${view_top < elem_top}) && elem_top - view_top -  < window_section (${elem_top - view_top < window_section})`);
  return view_top < elem_top && ((elem_top - view_top) < window_section);
};

pomagamy.scrollToElem = function($elem, ofst, is_err) {
  var extra_offset = ofst || 0,
    is_error = is_err || false;

  if ($elem.length && !isScrolledIntoView($elem, extra_offset, is_error)) {
    var cord_y = $elem.offset().top - 50 - extra_offset; // 50 is an arbitrary offset
    //if (is_error){
    //    cord_y = cord_y + ($elem.height() - $window.height())/2 + 50;
    //    //cord_y = cord_y + $elem.height() - $window.height() + 100;
    //}
    $('html, body').animate(
      {
        scrollTop: cord_y,
      },
      1000,
    );
  }
};

store.set('perma', false);

pomagamy.has_adblock = function() {
  return has_adblock;
};

var position;
pomagamy.show_popup = function(url_or_selector, onload_extra_callback) {
  function onloadFunction($elem) {
    init_inputs($elem);
    if (onload_extra_callback) {
      onload_extra_callback();
    }
  }

  function adjustScrollableBody($popup_outer_wrapper) {
    // Adjusts TOS popup inner body.
    var $popup_body = $popup_outer_wrapper.find('.popup-body');
    $popup_body.css({
      'padding-top': 0,
      height:
        $window.innerHeight() -
        $popup_outer_wrapper.find('.popup-header').outerHeight() -
        $popup_outer_wrapper.find('.popup-footer').outerHeight() -
        (parseInt($popup_body.css('padding-top').replace('px', '')) +
          parseInt($popup_body.css('padding-bottom').replace('px', ''))) -
        88,
      position: $window.innerWidth() < 768 ? 'relative' : 'absolute',
      'overflow-y': 'scroll',
    });
    $popup_outer_wrapper.find('.popup-footer').css({
      position: 'absolute',
      width: '100%',
      bottom: 0,
    });
    $popup_outer_wrapper.find('.modal-dialog').css({
      height: '100%',
    });
  }

  function showPopup($popup) {
    let popupix;
    // OnLoad
    $popup.on('show.bs.modal', function(e) {
      if (store.get('perma')) {
        $popup.find('.round-close-icon').hide();
      }

      store.observe('perma', (val, oldVal) => {
        if (val) {
          $popup.find('.round-close-icon').hide();
        } else {
          $popup.find('.round-close-icon').show();
        }
      });

      $body.append('<div class="fix-modal-overlay"></div>');

      onloadFunction($popup);
      position = $(document).scrollTop();
      // Edge case: TOS popup
      var $target = $(e.currentTarget);
      if ($target.find('.tos').length) {
        adjustScrollableBody($target);
      }
      // Fix for iOS popups - force bottom safari toolbar.
      if (mobile) {
        $('html,body').css({
          position: 'fixed',
          height: 0,
        });
      }
    });
    $popup.on('shown.bs.modal', function(e) {
      setTimeout(function() {
        $('.fix-modal-overlay').remove();
      }, 100);
    });
    $popup.on('hide.bs.modal', function(e) {
      if (store.get('perma')) {
        return false;
      }
      if (mobile) {
        $('html, body')
          .removeAttr('style')
          .scrollTop(position);
      }
    });
    $popup.on('hidden.bs.modal', function(e) {
      // Cleanup popup limbo space.
      if ($popup.parent().is('#bs-limbo')) {
        $popup.remove();
      }
    });

    popupix = $popup.modal();

    store.observe('perma', (val, oldVal) => {
      if (popupix && popupix.data('bs.modal')) {
        if (val) {
          popupix.data('bs.modal')._config.keyboard = false;
          popupix.data('bs.modal')._config.backdrop = 'static';
        } else {
          popupix.data('bs.modal')._config.keyboard = true;
          popupix.data('bs.modal')._config.backdrop = true;
        }
      }
    });
  }

  var inline = url_or_selector.lastIndexOf('#', 0) === 0;

  if (inline) {
    showPopup($(url_or_selector));
  } else {
    $.get(url_or_selector, {}, function(response) {
      var $wrapper = $popups.find('#bs-limbo');
      // Handle rare cases of JsonResponse with popup in response.html (ex. allauth views)
      var content = url_or_selector.indexOf('json2html') !== -1 ? response.html : $(response);
      $wrapper.append(content);
      showPopup($wrapper.find('.modal').last());
    });
  }
};

pomagamy.close_popup = function() {
  setTimeout(function() {
    //fix for bootstrap4 modals
    var $modal = $popups.find('.modal.show');
    if ($modal.length) {
      $modal.modal('hide');
    }
  });
};

var initCloseMessage = function() {
  var $close_msg = $('.js-close-message');
  if ($close_msg.length) {
    $close_msg.on('click', function(e) {
      $(this)
        .closest('.message-fixed, .message-bar')
        .remove();
      return false;
    });
  }
};

var checkStatus = function(context) {
  $('.check-status', context)
    .parent()
    .on('click', function(e) {
      if (
        $(this).hasClass('active') ||
        $(this).hasClass('disabled') ||
        $(this).hasClass('js_disabled')
      ) {
        return;
      }

      $('.check-list')
        .find('.active')
        .removeClass('active');
      $(this)
        .addClass('active')
        .find(':radio')
        .trigger('pr-switch-checked', 1);
    });
};

var initCheckStatus = function(context) {
  $('input[type="radio"]:checked', context)
    .closest('.check-status')
    .closest('li')
    .addClass('active');
};

var scrollMenu = function($scrollMenu) {
  var footerless = $scrollMenu.is('.footerless');
  $scrollMenu.pomagamyFixedInside({
    mode: 'menu',
    stickHelper: footerless ? $('#section').find('.container') : $('#footer'),
    footerLess: footerless,
    tabletBreakPoint: tabletBreakPoint,
    stickOffsetHelper: 900,
    stickOffset: 100,
  });
};

function userSidebar($headerUser, $profileSidebar) {
  var $overflow = $(
      '<div class="profile-sidebar-overflow" style="display: none;" id="profile-sidebar-overflow"></div>',
    ),
    $close = $('#profile-sidebar-header-close');

  $profileSidebar.before($overflow);

  function bindToggle($elem) {
    $elem.on('click.sidebar', function(e) {
      e.preventDefault();
      $overflow.fadeIn();
      $('body').css('overflow', 'hidden');
      $('#page').css('overflow-y', 'scroll');
      $profileSidebar.show().animate({
        right: 0,
      });
    });
  }

  if ($headerUser.find('#js-utility-project-btn').length) {
    bindToggle($headerUser.find('.header-user-menu-toggle'));
  } else {
    bindToggle($headerUser);
  }

  $close.on('click.close', function(e) {
    $('body').css('overflow', 'initial');
    $('#page').css('overflow-y', 'initial');
    $overflow.fadeOut();
    $profileSidebar.animate(
      {
        right: '-400px',
      },
      {
        complete: function() {
          $profileSidebar.hide();
        },
      },
    );
    $window.off('resize.sidebar');
    e.preventDefault();
  });

  $overflow.on('click', function() {
    $close.triggerHandler('click.close');
  });
}

var mobileHomePage = function(obj) {
  var $button = $(obj),
    offsetTop = $button.offset().top + 50;

  $window.on('scroll', function() {
    if (window.pageYOffset > offsetTop) {
      $button.addClass('active');
    } else {
      $button.removeClass('active');
    }
  });
};

var $socialShareUrls = $('#social-shares'),
  registerShare = function() {
    // posts to endpoint to +1 to share count of current project, refresh displayed stats.
    $.post($socialShareUrls.data('url')).done(function(data) {
      $('span.js-social-share-counter').html(data.share_count);
      $('span.js-social-share-label').html(data.share_label);
    });
  },
  facebookShareDialog = function(obj) {
    $(obj).click(function(e) {
      if ($(this).hasClass('disabled')) {
        return;
      }

      // Skip when FB is not loaded yet
      if (typeof(FB) === 'undefined'){
        return;
      }

      e.preventDefault();

      FB.ui(fb_feed_data, function(response) {
        registerShare();
      });
    });
  },
  facebookSendDialog = function(obj) {
    $(obj).on('click', function(e) {
      if ($(this).hasClass('disabled')) {
        return;
      }

      // Send Dialog is not supported anymore on mobile, let's open dedicated app instead.
      if (mobile) {
        const url = 'fb-messenger://share?link=' + encodeURIComponent(fb_feed_data['link']) + '&utm_source=messenger&app_id=' + encodeURIComponent(fb_feed_data['app_id']);
        window.open(url);
        return;
      }

      // Skip when FB is not loaded yet
      if (typeof (FB) === 'undefined') {
        return;
      }

      FB.ui(
        {
          method: 'send',
          link:
            fb_feed_data['link'] +
            '?utm_source=messenger&utm_medium=invitation',
        },
        function(response) {
          if (response && response.success === true) {
            if ($.fn.messengerShareCallback) {
              $.fn.messengerShareCallback();
            }
          }
        },
      );
    });
  },
  whatsAppShare = function (obj) {
    $(obj).on('click', function (e) {
      e.preventDefault();
      // WhatsApp 'click to chat' docs: https://faq.whatsapp.com/5913398998672934
      window.open('https://wa.me/?text=' + encodeURIComponent(fb_feed_data['link']));
      registerShare();
    });
  };

var loadTwitter = function() {
  if (typeof twttr !== 'undefined') {
    return;
  }
  var p = /^http:/.test(document.location) ? 'http' : 'https',
    $socialShareUrls = $('#social-shares');
  $.getScript(p + '://platform.twitter.com/widgets.js', function() {
    if (typeof twttr !== 'undefined' && $socialShareUrls.length) {
      twttr.ready(function(twttr) {
        $body.on('click', 'a.js-twt', function(e) {
          e.preventDefault();
          var share_url = $(this).attr('data-target'),
            popup_width = 550,
            popup_left_offset =
              window.screenX + ($(window).width() - popup_width) / 2;
          window.open(
            share_url + '&',
            'twitterwindow',
            'height=450, width=' +
              popup_width +
              ', top=' +
              ($(window).height() / 2 - 225) +
              ', left=' +
              popup_left_offset +
              ', personalbar=0, toolbar=0, location=0, menubar=0, directories=0, scrollbars=1, resizable=1',
          );
          registerShare();
        });
      });
    }
  });
};

var do_csrf_magical_stuff = function() {
  function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      var cookies = document.cookie.split(';');
      for (var i = 0; i < cookies.length; i++) {
        var cookie = $.trim(cookies[i]);
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + '=') {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  var csrftoken = getCookie('csrftoken');

  function csrfSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
  }

  $.ajaxSetup({
    beforeSend: function(xhr, settings) {
      // Then using Webpack, X-Requested-With header is required on every
      // request for common.is_ajax(request) to work properly.
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      if (!csrfSafeMethod(settings.type)) {
        xhr.setRequestHeader('X-CSRFToken', csrftoken);
      }
    },
  });
};

var dateCountdownTimer = function($elem) {
  var end_date = new Date($elem.data('target')),
    _second = 1000,
    _minute = _second * 60,
    _hour = _minute * 60,
    _day = _hour * 24,
    timer;

  function lPad(str, padString, length) {
    str = '' + str;
    while (str.length < length) {
      str = padString + str;
    }
    return str;
  }

  function showRemaining() {
    var now_date = new Date(),
      delta = end_date - now_date;
    if (delta < 0) {
      clearInterval(timer);
      $elem.html('00:00:00');
      return;
    }
    var days = Math.floor(delta / _day),
      hours = Math.floor((delta % _day) / _hour),
      minutes = Math.floor((delta % _hour) / _minute),
      seconds = Math.floor((delta % _minute) / _second),
      day_label = days === 1 ? ' dzień ' : ' dni ';

    $elem.html(
      days +
        day_label +
        lPad(hours, '0', 2) +
        ':' +
        lPad(minutes, '0', 2) +
        ':' +
        lPad(seconds, '0', 2),
    );
  }

  timer = setInterval(showRemaining, 1000);
};

function initScrollingWaypoints($elem, click_before_scroll) {
  var click_target = click_before_scroll || false;
  var scrollToTarget = function(href) {
    if (href.indexOf('##') > -1) {
      var $target = $(href.substring(1));
      if ($target.length) {
        if (click_target && !$target.is('.active')) {
          $target.click();
        }
        pomagamy.scrollToElem($target);
      }
      return false;
    }
  };

  // Internal linking
  $elem.on('click', 'a', function(e) {
    e.stopPropagation();
    var href = $(this).attr('href');
    scrollToTarget(href, true);
  });

  // On load: Scroll and show answer, when link is in given format: /faq/##button_id
  var start_hash = window.location.hash;
  if (start_hash) {
    scrollToTarget(start_hash);
  }
}

function initTOS() {
  if (!$page.is('.tos')) {
    return false;
  }
  initScrollingWaypoints($page.find('#tos-body'), false);
}

function initFaq() {
  if (!$page.is('.faq-page, .abuse-report')) {
    return false;
  }
  var $faq = $page.find('#faq'),
    $mobileButton = $('.header-bottom');

  if ($body.is('.mac')) {
    $('.menu-nav')
      .removeClass('fixed-inside-menu')
      .closest('.hide-element')
      .addClass('js-mac-sticky');
  }

  $faq.on('click', '.faq-list .wrap-info', function() {
    const $this = $(this);
    setTimeout(function() {
      $(window).trigger('resize.pfi');
      if (!$this.is('.collapsed') && typeof dataLayer !== 'undefined') {
        dataLayer.push({
          title: $this.find('.title').text(),
          event: 'FAQ - open block',
        });
      }
    }, 500);
  });

  $('.collapse').on('shown.bs.collapse', function() {
    pomagamy.scrollToElem($(this), 0, true);
  });

  initScrollingWaypoints($faq, true);

  if (mobile && $mobileButton.length) {
    mobileHomePage($mobileButton);
  }
}

function initOnePercent() {
  if (!$page.is('.one-percent')) {
    return false;
  }
  const hidden_cls = 'hidden';
  const active_cls = 'active';
  const $pitCard = $page.find('.js-pit-card');
  $pitCard.one('click', function() {
    $page.find('.js-details-wrapper').removeClass(hidden_cls);
  });

  $pitCard.on('click', function() {
    const $this = $(this);
    $this.siblings(`.${active_cls}`).removeClass(active_cls);
    $this.addClass(active_cls);

    $page.find('.js-pit-type').text($this.find('h3').text());

    $page.find('.js-details').addClass(hidden_cls);
    const $target = $page.find($(this).data('target'));
    $target.removeClass(hidden_cls);
    pomagamy.scrollToElem($target, mobile ? 80 : 0);
  });
}

function initLazyEmbeds() {
  var $play_button = $('#play-button-source');
  if (!$play_button.length) {
    return;
  }

  // Mobile devices don't support autoplay of embeds.
  // But Youtube iframe api saves the day!
  var yt_api_loaded = false;
  var yt_api_ready = false;

  $('.embed-lazy').each(function() {
    var $container = $(this),
      url = $container.data('url'),
      video_id = url.split('/').pop().split('?')[0],
      is_youtube = url.indexOf('youtube') > -1,
      is_main_embed = $container.hasClass('embed-main');

    $play_button
      .clone()
      .removeAttr('id')
      .removeClass('hidden')
      .appendTo($container);

    // Get thumbnail from yt/vimeo server for paragraph embeds.
    if (!is_main_embed) {
      if (is_youtube) {
        $container.css(
          'background-image',
          'url(https://i.ytimg.com/vi/' + video_id + '/hq720.jpg)',
        );
      } else {
        $.ajax({
          type: 'GET',
          url: 'https://vimeo.com/api/v2/video/' + video_id + '.json',
          jsonp: 'callback',
          dataType: 'jsonp',
          success: function(data) {
            var thumbnail_src = data[0].thumbnail_large;
            $container.css('background-image', 'url(' + thumbnail_src + ')');
          },
        });
      }
    }

    function appendIframe($elem) {
      // Create an iFrame with autoplay set to true
      var iframe_url = url.split('?')[0] + '?rel=0&showinfo=0&autohide=1&autoplay=1&enablejsapi=1';

      function preserve_param(param_name) {
        // Adds selected stripped params back
        var param_value = getUrlParamByName(param_name, url);
        if (param_value) {
          iframe_url = iframe_url + '&' + param_name + '=' + param_value;
        }
      }
      preserve_param('start');
      preserve_param('end');

      console.log(iframe_url);
      var iframe = $('<iframe/>', {
        class: 'embed-responsive-item',
        frameborder: '0',
        src: iframe_url,
        webkitallowfullscreen: '',
        mozallowfullscreen: '',
        allowfullscreen: '',
        allow: 'autoplay',
      });
      // Width and height are added as attributes on purpose.
      iframe.attr('width', 560).attr('height', 315);
      $elem.off('click'); // Not needed anymore.
      $elem
        .find('svg')
        .css('opacity', 0)
        .remove();
      $elem.append(iframe);

      // Fix for vimeo embeds not providing black background if video is smaller than container.
      if (is_main_embed) {
        $container.css('background', '#000000');
      }

      if (is_youtube && yt_api_ready) {
        new YT.Player(iframe[0], {
          events: {
            onReady: function(e) {
              e.target.playVideo();
            },
            // onStateChange: function(e) {
            //   console.log('changed state');
            //   console.log(e);
            // },
          },
        });
      }
    }

    $container.on('click', function() {
      if (mobile && is_youtube) {
        if (!yt_api_loaded) {
          $.getScript('https://www.youtube.com/iframe_api')
            .done(function() {
              yt_api_loaded = true;
              if (!yt_api_ready) {
                window.onYouTubeIframeAPIReady = function() {
                  yt_api_ready = true;
                  appendIframe($container);
                };
              }
            })
            .fail(function() {
              // append iframe anyways, autoplay on click just won't work
              appendIframe($container);
            });
        } else {
          appendIframe($container);
        }
      } else {
        appendIframe($container);
      }
    });
  });
}

//-------------------------------------------------------------------------------

var registrationBgFix = function() {
  var $bg = $page.not('.share-wizard').find('.full-background');
  var _height = function() {
    $bg.height($bg.find('.share-wizard-wrapper').height());
    $bg.css({ height: 'auto' });
    $bg.height($(window).height());
  };

  if ($bg.length) {
    _height();
    $(window).on('resize', function() {
      _height();
    });
  }
};

var init_fb_account_name = function(context) {
  // If user has connected account, load name
  var fb_name_container = $('.js-connected-fb-account-container', context);

  if (fb_name_container.length) {
    $.get(fb_name_container.data('url')).done(function(data) {
      $('.fb-account-name').append(data.name);
    });
  }
};

var AdCampaignDataToCookie = function() {
  // Saves UTM campaign data in cookie for further tracking.
  var keys = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_content',
    ],
    google_regexp = '^https?://www.google.(.+)?',
    max_value_length = 50,
    key,
    value,
    campaign_data = {};

  function hasKeys(dict) {
    return Object.keys(dict).length !== 0;
  }

  for (var i = 0; i < keys.length; i++) {
    key = keys[i];
    value = getUrlParamByName(key);
    if (value) {
      campaign_data[key] = value.substring(0, max_value_length);
    }
  }
  if (
    !hasKeys(campaign_data) &&
    document.referrer.toLowerCase().match(google_regexp)
  ) {
    campaign_data = {
      utm_source: 'google',
      utm_medium: 'organic',
    };
  }
  if (hasKeys(campaign_data)) {
    campaign_data['timestamp'] = Date.now() / 1000;   // This division is needed be comatilibe with python timestamps.
    setCookie('acd', JSON.stringify(campaign_data), 30);
  }
  return campaign_data;
};

var storeRecentProject = function() {
  // Don't call storage endpoint when user enters from homepage (src=ctg)
  if (!$page.is('.project-details, .project-wizard, .manager, .withdraw')) {
    return;
  }
  const $endpoint = $page.find('#recent-endpoint');
  const came_from_homepage = getUrlParamByName('src') === 'ctg';
  if ($endpoint.length && !came_from_homepage) {
    $.ajax({
      url: $endpoint.data('target'),
      type: 'GET',
    });
  }
};

var initHighestSupportsList = function() {
  var $btn_supports_highest = $('#btn-supports-highest'),
    load_highest_supports_handler = function() {
      var $this = $(this),
        url = $this.data('url'),
        page_type = $this.data('page-type'),
        $target = $('#' + $this.attr('href'));

      ajax = $.ajax({
        url: url,
        type: 'GET',
        data: { type: page_type, current_page: 0 },
      });
      ajax.done(function(response) {
        var $content = $(response.html);
        $target.html($content);
        $content.find('.read-more-button').pLoader();
        $btn_supports_highest.off('click', load_highest_supports_handler);
      });
    };
  if ($btn_supports_highest.length) {
    $btn_supports_highest.on('click', load_highest_supports_handler);
  }
};

var initConversationList = function(context) {
  var $conversation = $('#conversation-list', context);
  if ($conversation.length) {
    var $input = $conversation.find('input.load-more'),
      current_page = parseInt($input.data('current-page')),
      url = $input.data('url'),
      $target = $($input.data('target')),
      type = $input.data('type'),
      id = $input.data('object-id'),
      loading = false,
      loadingInfo = $conversation.find('#loading');

    loadingInfo.hide();

    $('.js-user').on('click', function() {
      var $elem = $('#new-message').length
        ? $('#new-message')
        : $('#message-main-container');
      pomagamy.scrollToElem($elem);
    });

    $conversation.on('scroll', function() {
      if (this.scrollTop + 100 >= this.scrollHeight - this.offsetHeight) {
        if ($input.length && current_page > -1 && !loading) {
          loading = true;
          loadingInfo.show();
          ajax = $.ajax({
            url: url,
            type: 'GET',
            data: { current_page: current_page, type: type, id: id },
          });
          ajax.done(function(data) {
            $target.append(data.html);
            if (data.has_next === true) {
              current_page = current_page + 1;
              loading = false;
            } else {
              current_page = -1;
            }
            loadingInfo.hide();
          });
        }
      }
    });
  }
};

var initMarkUnread = function(context) {
  var $el = $('#mark-unread', context);
  if ($el.length) {
    $el.on('click', function(e) {
      e.preventDefault();
      e.stopPropagation();
      $.ajax({
        url: $el.attr('href'),
        type: 'POST',
      })
        .done(function() {
          $el.toggleClass('active');
          $($el.data('target')).toggleClass('new-item');
        })
        .fail(function() {
          console.error('Something went wrong...');
        });
    });
  }
};

var initLockedFormScroll = function() {
  $page.on('click', '.js-lock-on-form.disabled', function() {
    var $opened_form = $('.container-form.open:not(.hidden-by-dynamic)');
    if ($opened_form.length) {
      pomagamy.scrollToElem($opened_form);
    }
    return false;
  });
};

var initAbuseReportDetails = function() {
  $body.on('click', '.js-get-details', function() {
    var $this = $(this);
    $.get($this.data('target')).done(function(data) {
      var $wrapper = $this.closest('.modal-dialog').find('.js-report-details');
      $wrapper.removeClass('hidden').empty();
      $wrapper.append($.parseHTML(data.text));
    });
    return false;
  });
}

var initProjectWizardLockdown = function() {
  if ($page.is('.js-locked')) {
    store.set('perma', true);
    $popups.find('.round-close-icon').hide();
  }
};

var initProjectWizardBasicInfo = function() {
  if (!$page.is('.project-wizard-intro')) {
    return;
  }
  const $form = $page.find('form#intro');
  const $ga_client_id = $form.find('#id_ga_client_id');

  // Set value for extra form field.
  $form.find('#id_is_mobile').val(window.mobileAndTabletcheck());

  var $goal = $form.find('#id_goal'),
    $currency = $goal.siblings('i.currency');
  $currency.on('click', function() {
    $goal.focus();
  });
  $goal.focus();
  initProjectWizardLockdown();

  $form.on('click', '#intro-next', function(e) {
    // Save GA client id and submit form.
    e.preventDefault();
    e.stopPropagation();
    storeGAid($ga_client_id);
    $form.submit();
    return false;
  });
};

const fetchRemoteHTML = $element => {
  if (!$element)
    throw new Error('No $element were provided in fetchRemoteHTML');
  const url = $element.data('url');

  if ($element.hasClass('disabled')) {
    return;
  }

  ajax = $.ajax({
    url: url,
    dataType: 'html',
  });

  ajax.done(function(response) {
    const $response = $(response);
    $response.appendTo($element);

    checkStatus($response);
    init_inputs($response);

    if (enable_beforeunload_message) {
      //save initial form data
      $opened_form = $response.find('form');
      opened_form_initial_data = $opened_form.serialize();
    }

    // init word counter
    const cnt = $response.find('.show-counter');
    if (cnt.length) {
      cnt.pomagamyCounter();
    }
  });

  ajax.fail(function() {
    console.error('error');
  });
};

var initProjectWizardEditor = function() {
  fetchRemoteHTML($('#create-project-element'));
  fetchRemoteHTML($('#create-project-description-element'));
  fetchRemoteHTML($('#editor-element'));
};

var initProjectWizard = function() {
  if (!$page.is('.project-wizard')) {
    return;
  }
  enable_beforeunload_message = !mobile;

  initProjectWizardEditor();
  initProjectWizardLockdown();
  initLockedFormScroll();
  storeRecentProject();

  var toolbarPosition,
    resize = false;

  $window.on('resize', function() {
    resize = true;
  });

  $window.on('scroll', function() {
    if ((!toolbarPosition || resize) && $('.fr-box').length > 0) {
      toolbarPosition = $('.fr-box').offset().top;
      resize = false;
    }
    const toolbar = $('.fr-toolbar');
    const editorContent = $('.paragraph-editor');

    let isToolbarExceedingEditor = false;
    if (editorContent.length) {
      isToolbarExceedingEditor =
        $window.scrollTop() >=
        editorContent.offset().top +
          editorContent.height() -
          toolbar.height();

      const toolbarStyles = getComputedStyle(toolbar[0]);
      const stripPxToNumber = str => {
        return Number(str.replace('px', ''));
      };
      toolbar.width(
        document.querySelector('.fr-element.fr-view').clientWidth -
          stripPxToNumber(toolbarStyles.paddingLeft) -
          stripPxToNumber(toolbarStyles.paddingRight),
      );
    }

    if (
      $window.scrollTop() >= toolbarPosition &&
      toolbar.length > 0 &&
      !isToolbarExceedingEditor
    ) {
      toolbar.addClass('fixed');
      toolbar.css({
        top: 0,
      });
    } else {
      toolbar.removeClass('fixed');
      toolbar.css({
        top: 0,
      });
    }
  });

  $(document).on('click focus', '#id_embed', function(e) {
    if ($(window).width() < 768) {
      e.stopPropagation();
      $('.popup-body').addClass('moved-up');
    }
  });

  $(document).on('click', function() {
    var $popup_body = $('.popup-body');
    if ($(window).width() < 768 && $popup_body.is('.moved-up')) {
      $popup_body.removeClass('moved-up');
    }
  });

  $('#js-story-box').on('focus', '#story-error-wrapper', function() {
    removeFormErrors();
  });

  if (enable_beforeunload_message) {
    $(window).bind('beforeunload', function(e) {
      if ($opened_form) {
        var form_state = $opened_form.serialize();
        if (opened_form_initial_data !== form_state) {
          /* Translators: This message appears when user tries to close browser before saving in ProjectWizard.*/
          var message = gettext('prowiz-unsaved-changes');
          e.returnValue = message; // Cross-browser compatibility (src: MDN)
          return message;
        }
      }
    });
  }

  const $fee_tier_box = $page.find('.js-fee-tier-box');
  if ($fee_tier_box.length) {
    const tier_element_selector = '.js-plan-box';
    const selected_class = 'selected';
    const $confirm_wrapper = $fee_tier_box.find('.js-confirmation-wrapper');
    const $launch_btn = $fee_tier_box.find('#launch-btn');
    const is_draft = $fee_tier_box.data('is-draft') === 'True';
    let initial_tier = $fee_tier_box.find(tier_element_selector + '.' + selected_class).data('value');

    $.fn.CommonAjaxCallback = function(data) {
      if (data.current_tier) {
        initial_tier = data.current_tier;
        // This is a bit of a hack, but some other mechanism applies stype affecting display.
        // That's the reason an attribute is used instead of class and the style is removed after a timeout.
        $launch_btn.attr('hidden', true);
        setTimeout(function() {
          $launch_btn.attr('style', '');
        }, 500);
      }
      if (data.lock_tier) {
        // Disable unselected option when user cannot change tiers anymore
        $fee_tier_box.find(tier_element_selector).not('.selected').addClass('disabled');
      }
      $confirm_wrapper.addClass('hidden');
      // Uncheck the checkbox - it uses custom plugin pomagamyCheckboxDefault
      $confirm_wrapper.find('#id_confirm_tier').trigger('pr-switch-checked', 0);
    };

    $fee_tier_box.on('click', tier_element_selector, function(e) {
      const $this = $(this);
      if ($this.hasClass(selected_class) || $this.hasClass('disabled')) {
        return;
      }

      const selected_tier = $this.data('value');
      const requires_confirmation = $this.data('requires-confirmation') === 'True';

      if (!is_draft) {
        // Hides fee tier submit button when tier's not changed
        $launch_btn.attr('hidden', selected_tier === initial_tier);
      }

      $confirm_wrapper.toggleClass('hidden', selected_tier === initial_tier || !requires_confirmation);
      if (requires_confirmation) {
        $fee_tier_box.find('.js-confirm-msg').addClass('hidden');
        $fee_tier_box.find($this.data('msg-obj')).removeClass('hidden');
      }

      removeFormErrors();
      $fee_tier_box.find('.' + selected_class).removeClass(selected_class);
      $this.addClass(selected_class);
      $fee_tier_box.find('input#id_fee_tier').val(selected_tier);
    });
  }
};

var initPayment = function() {
  var $ctx = $('body.payment');
  if (!$ctx.length) {
    return;
  }

  const $form = $('#payment-form');
  const $pledge = $form.find('#id_pledge');

  const donation_slider_default_value = $form.is('.js-zero-donation') ? 0 : 15;
  const hide_donation = $form.is('.js-hide-donation');

  const ab_variants = {
    0: {},
    1: {
      // Exp47 stuff
      // Donation preset values in percentages (need to be calculated from selected pledge)
      donation_main_percentages: [0, 7.5, 15],
      donation_extra_percentages: [2, 4, 6],
    },
  };
  const variant = $page.find('#id_ab_variant').val();
  const variant_data = ab_variants[variant];
  // TODO: when you need to set up an experiment start here

  // Business requirement: once one newsletter signup checkbox is selected, the other one should be hidden.
  const newsletter_checkbox_selector = '.js-newsletter-checkbox';
  const newsletter_wrapper_selector = '.js-newsletter-wrapper';
  const checkbox_wrapper_selector = '.js-payment-checkbox-wrapper';
  const accept_tos_selector = '#id_accept_pomagam_tos';
  const is_tos_checkbox_present = $form.find(accept_tos_selector).length;

  $form.on('click', newsletter_checkbox_selector, function() {
    const $this = $(this);
    $form
      .find(newsletter_checkbox_selector).not(':checked')
      .closest(newsletter_wrapper_selector)
      .toggleClass('hidden', $this.is(':checked'));

    // Ensure that Accept TOS checkbox is present.
    // That means there are at least two checkboxes so they should be aligned to the left.
    if (is_tos_checkbox_present) {
      // If first newsletter checkbox (contains _1 in ID) is selected, set text-center class on checkbox wrapper.
      const $checkbox_wrapper = $form.find(checkbox_wrapper_selector);
      if ($this.attr('id').includes('_1') && $this.is(':checked')) {
        $checkbox_wrapper.addClass('text-center');
      } else {
        $checkbox_wrapper.removeClass('text-center');
      }
    }
  });

  // Show extra information box on donation value change to 0 (either slider or manually input)
  const $donation_zero_info_box = $form.find('#donation-zero-info-box');

  function toggleDonationInfoBox(show) {
    if (!$donation_zero_info_box.length) {
      return;
    }
    $donation_zero_info_box.toggleClass('hidden', !show);
  }

  $page.find('#id_is_mobile').val(window.mobileAndTabletcheck());

  hideOddMobilePaymentChannel($form);

  initPledgeButtons($form);

  let slider;
  const donation_slider_step = 1;
  const exchange_rates_available = window.exchange_rates.length > 0;

  const isExpVariantB = variant == 1;

  function update_payment_summary() {
    const pledge_value = inputIntValue($pledge);
    const donation_value = isExpVariantB ? updateVariantBDonationValue(pledge_value, $form) : updateDonationValue(pledge_value, slider, $form);
    if (exchange_rates_available) {
      calculateRates(pledge_value, donation_value, $form);
    }
    updateSummaryValues(pledge_value, donation_value, $form);
  }

  if (isExpVariantB) {
    initExp47VariantB($form, variant_data, update_payment_summary);
  }

  if (!hide_donation && !isExpVariantB) {
    slider = createSlider(
      donation_slider_default_value,
      donation_slider_step,
      update_payment_summary,
      $form,
      toggleDonationInfoBox,
    );
  }

  initCustomDonationHelperEvents($form, update_payment_summary, toggleDonationInfoBox);
  initPledgeEvents($form, $pledge, slider, update_payment_summary);

  intIncreasePledgeToMinValue($form);
  initTosCheckEvent($form);
  initPaymentChannelsEvents($form);

  $.fn.CommonAjaxCallback = function(data) {
    var channel_name = $('input[name=payment_channel]:checked', '#payment-form')
      .next('img')
      .attr('alt');
    if (data.popup_html && channel_name !== 'PayPal') {
      // Append popup content.
      $popups.append(data.popup_html);

      // It doesn't work without timeout.
      setTimeout(function() {
        pomagamy.show_popup('#how-to-pay-popup', function() {
          HowToPayOnLoad($popups);
        });
      }, 300);
    } else if (data.create_zen_tx) {
      if (data.create_zen_tx.is_apple_pay) {
        // ZEN Apple Pay:
        // Begin Apple Pay session created before submitting the main payment form.
        beginApplePaySession(data.create_zen_tx);
      } else {
        // ZEN Credit Card:
        // Get a fingerprint and submit ZEN tx form
        createZenTx(data.create_zen_tx.sid, false, true);
      }
    } else if (data.show_gpay) {
      showGooglePayDialog(data);
    } else if (data.show_zen_cc_widget) {
      const openPopup = checkoutId => {
        const callbacks = {
          cancelCallback: () => {
            $.growl.error({
              title: gettext('cmn-error'),
              message: gettext('supwiz-failed-or-canceled-payment'),
            });
          },
          successCallback: () => {
            window.location.href = data.show_zen_cc_widget.success_url;
          },
          failureCallback: () => {
            $.growl.error({
              title: gettext('cmn-error'),
              message: gettext('supwiz-failed-or-canceled-payment'),
            });
          },
        };

        let language = decodeURIComponent(
          $form.attr('data-language').split('_')[0]
        );
        // convert 'bad' language code forced in django to the proper one.
        language = language === 'ua' ? 'uk' : language;
        widgetConfigParams.lang = language;

        // Optimization: populate ZEN tx form with fingerprint and browser data, without submitting it.
        createZenTx(data.sid, false, false);

        const checkout = new ZenCheckout(
          checkoutId,
          callbacks,
          widgetConfigParams,
        );
        checkout.paymentPopup();
        // stop loader AFTER showing ZEN widget.
        if ($.fn.stopPulseLoading) {
          $.fn.stopPulseLoading();
        }
      };

      openPopup(data.show_zen_cc_widget.checkout_id);
    } else {
      window.location.href = data.location;
    }
  };
  initZenPayments($form);
  initStripePayments($form);

  initOnlineSubmitEvent($form);

  initShowAllBanksEvent($form);
  initHideUserData($form);

  push_GA_add_event();
};

var initProjectComment = function() {
  if (!$page.is('.thank-you-page')) {
    return;
  }

  const $commentForm = $page.find('#comment-form');
  const $showNameField = $commentForm.find('#id_show_name');

  var $show_name_btn = $page.find('#js-trigger-identity'),
    hidden_class = 'hidden';

  if ($show_name_btn.length) {
    $show_name_btn
      .on('click', function() {
        var $this = $(this);

        $this.text($this.is('.shown') ? gettext('Hide') : gettext('Show'));
        if (!$this.is('.init')) {
          $this.addClass('init');
        } else {
          $('.identity-option').toggleClass(hidden_class);
          $showNameField.val($this.is('.shown'));
        }
        $this.toggleClass('shown');
      })
      .triggerHandler('click');
  }

  recentSupportNotification($page);
};

var initAutoSelectText = function() {
  // Copies element's text into clipboard.
  // Allows to point another text source by data-target attribute.

  var $elem = $('.js-autoselect');
  if (!$elem.length) {
    return;
  }
  $.fn.OneClickSelect = function() {
    return $(this).on('click', function(e) {
      e.preventDefault();

      var range, selection, target;

      var $target_elem = $($(this).data('target'));
      if ($target_elem.length) {
        target = $target_elem[0];
      } else {
        target = this;
      }

      if (window.getSelection) {
        selection = window.getSelection();
        range = document.createRange();
        range.selectNodeContents(target);
        selection.removeAllRanges();
        selection.addRange(range);
      } else if (document.body.createTextRange) {
        range = document.body.createTextRange();
        range.moveToElementText(target);
        range.select();
      }
      document.execCommand('copy');
      $.growl({ title: '', message: gettext('cmn-clipboard') });
      setTimeout(function(e) {
        if (window.getSelection) {
          window.getSelection().removeAllRanges();
        } else if (document.selection) {
          document.selection.empty();
        }
      }, 3500);
    });
  };
  $elem.OneClickSelect();
};
var initProjectDetails = function() {
  if (!$page.is('.project-details')) {
    return;
  }

  // The floating pledge button is visible only if the page scrolled below the button in payment-box
  // It's present ONLY when payments are available and user is not authenticated.
  var $elementToPass = $('.box.goal:not(.mobile)');
  var offsetTop = $elementToPass.offset().top + $elementToPass.height();
  var $sharePanel = $('.shar3-panel');
  $window.on('scroll load resize', function(e) {
    if (window.pageYOffset > offsetTop) {
      $sharePanel.addClass('active js-size-freeze');
    } else {
      $sharePanel.removeClass('active');
    }
  });

  initReadMore($page.find('#news .js-truncate'));
  storeRecentProject();

  var $story = $page.find('.story'),
    $anon_endpoint_elem = $('#utils-endpoint'),
    campaign_data = AdCampaignDataToCookie();

  if ($anon_endpoint_elem.length) {
    $.ajax({
      url: $anon_endpoint_elem.data('target'),
      type: 'GET',
    })
      .done(function(data) {
        if (data.popup_url) {
          pomagamy.show_popup(data.popup_url);
        }
      })
      .fail(function() {
        console.error('Something went wrong...');
      });
  }

  var $links = $story.find('p > a');
  $links.each(function(i) {
    var $this = $(this),
      text = $this.text(),
      parts = [],
      index,
      delimiter;

    // Remove proto
    delimiter = '://';
    index = text.indexOf(delimiter);
    if (index > -1) {
      text = text.split(delimiter).pop();
    }
    // Remove www.
    delimiter = 'www.';
    index = text.indexOf(delimiter);
    if (index === 0) {
      text = text.split(delimiter).pop();
    }
    // Get hostname
    delimiter = '/';
    index = text.indexOf(delimiter);
    if (index > -1) {
      parts.push(text.substring(0, text.indexOf(delimiter) + delimiter.length));
      text = text.substring(text.indexOf(delimiter) + delimiter.length);

      // Apply ellipsis on the remaining part
      if (text.length > 12) {
        text = text.substring(0, 10) + '...';
        parts.push(text);
        text = parts.join('');
        $this.text(text);
      }
    }
  });

  initHighestSupportsList();

  if (
    campaign_data.utm_source === 'email' &&
    campaign_data.utm_medium === 'news'
  ) {
    pomagamy.scrollToElem($('#news'), 80);
  }

  push_GA_details_event();
  recentSupportNotification($page);

  // There are two pledge elements (desktop+mobile).
  // Only one is visible at a time.
  const $pledge = $page.find('.js-pledge-label:visible');
  const big_pledge = parseInt($pledge.data('threshold'));
  const $count_label = $page.find('.js-count-label');
  const stats_url = $page.find('#stats-endpoint').data('target');
  function formatNumber(val) {
    return val.formatMoney(
      0,
      '',
      get_format('THOUSAND_SEPARATOR'),
      get_format('DECIMAL_SEPARATOR'),
    );
  }

  if ($pledge.data('refresh') === 'True') {
    let fetching_stats = false;

    function getStats() {
      if (fetching_stats) {
        return;
      }
      if (!isScrolledIntoView($pledge, 0, true)) {
        return;
      }

      fetching_stats = true;

      $.ajax({
        url: stats_url,
      })
        .done(function(data) {
          const old_pledge = parseInt($pledge.data('value'));
          const new_pledge = parseInt(data.pledge);
          if (new_pledge !== old_pledge) {
            $pledge.data('value', new_pledge);
            if (old_pledge < big_pledge && new_pledge >= big_pledge) {
              $pledge.addClass('over100k');
            }
            pledge_countup.update(new_pledge);
          }

          const supporters_count = parseInt(data.count);
          const count_label = formatNumber(supporters_count);
          const supporters_payments_label = ngettext(
            'cmn-declination-donated',
            'cmn-declination-donated-plural',
            supporters_count,
          );
          const supporters_ppl_label = ngettext(
            'cmn-declination-person',
            'cmn-declination-people',
            supporters_count,
          );

          $count_label.html(
            `${supporters_payments_label} <span class="font-weight-bold">
         ${count_label} ${supporters_ppl_label}`,
          );

          if (supporters_count > 0) {
            $page.find('.js-quick-stats').removeClass('hidden');
          }

          const percentage = parseInt(data.percentage);
          const selector =
            percentage > 100 ? '.progress-bar.js-over100' : '.progress-bar';
          let fill_width = percentage % 100;
          if (percentage > 0 && fill_width === 0) {
            fill_width = 100;
          }
          $page.find(selector).css({ width: `${fill_width}%` });
        })
        .always(function(e) {
          fetching_stats = false;
        });
    }

    const startValue = $pledge.data('value');
    const options = {
      startVal: startValue,
      separator: '&nbsp;',
      suffix: ' zł',
    };

    let pledge_countup = new CountUp($pledge[0], startValue, options);

    setInterval(() => {
      getStats();
    }, 20 * 1000);
  }

  function shareLink(e) {
    e.preventDefault();
    const project_url = $(e.currentTarget).data('target');
    navigator.clipboard.writeText(project_url);
    $.growl({ title: '', message: gettext('cmn-clipboard') });
    registerShare();
  }

  let $share_popup = $body.find('#sh4r3-p0pup');
  if ($share_popup.length) {
    let shared = false;
    let register_share = false;

    $share_popup.on('hidden.bs.modal', function(e) {
      if (shared) {
        pomagamy.show_popup('#sh4r3-followup');
        shared = false;
        if (register_share) {
          registerShare();
          register_share = false;
        }
      }
    });
    $share_popup.on('click', 'ul>li', function(e) {
      shared = true;
      if (!$(this).hasClass('js-skip-reg')) {
        register_share = true;
      }
    });
    $share_popup.on('click', '.js-share-project-url', function(e) {
      shareLink(e);
      shared = true;
      register_share = false; // happens in `shareLink()`
    });

    // Used for Support follow-up mailing.
    // Opens share popup when there is openSharePopup query parameter in URL.
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('openSharePopup')) {
      pomagamy.show_popup('#sh4r3-p0pup');
      loadTwitter();
    }

    $page.find('.js-show-sh4r3').on('click', function() {
      pomagamy.show_popup('#sh4r3-p0pup');
      loadTwitter();
    });

    // inits Whatsapp for popup
    const $whatsapp_shares = $body.find('.js-wa-share');
    if ($whatsapp_shares.length) {
      whatsAppShare($whatsapp_shares);
    }
  }
};

var initAbuseReport = function() {
  if (!$page.is('.abuse-report')) {
    return;
  }
  var $sender_knows_author = $('#id_sender_knows_author'),
    $relationship_description = $('#id_relationship_description'),
    $user_type = $('#id_user_type'),
    $category = $('#id_category'),
    $message = $('#id_message'),
    $buttons = $('#buttons');

  // Hide empty option after revealing options list
  $('.my-select-display').on('click', function() {
    $(this)
      .siblings('.select-list-wrapper')
      .find('.select-list-item:first')
      .addClass('hidden');
  });

  $sender_knows_author.on('change.select', function() {
    var value = $sender_knows_author.val();
    if (value.length) {
      var text = $sender_knows_author.find('option:selected').data('label');
      $relationship_description.prev().text(text);
      $relationship_description.closest('div.form-group').removeClass('hidden');
    }
  });

  $relationship_description.on('keyup', function() {
    var value = $relationship_description.val();
    if (value.length) {
      $user_type.closest('div.form-group').removeClass('hidden');
    }
  });

  $user_type.on('change.select', function() {
    var value = $user_type.val();
    if (value.length) {
      $message
        .closest('div.form-group')
        .find('.error-msg')
        .remove();
      $message.removeClass('error');
    }

    if (value === '1' || value === '2') {
      // TODO: refactor, there must be a better way (show and select empty option in $category)
      $category
        .find('option:eq(0)')
        .prop('selected', true)
        .end()
        .triggerHandler('change.select');
      $category
        .siblings('.select-list-wrapper')
        .find('.selected')
        .removeClass('selected');
      $category
        .siblings('.select-list-wrapper')
        .find('.select-list-item:first')
        .removeClass('hidden')
        .addClass('selected');

      var new_label_text = $user_type.find('option:selected').data('label');
      $message.prev().text(new_label_text);
      $message.closest('div.form-group').removeClass('hidden');
      $buttons.removeClass('hidden');
      $category.closest('div.form-group').addClass('hidden');
    } else if (value === '3') {
      $category.closest('div.form-group').removeClass('hidden');
      $message.closest('div.form-group').addClass('hidden');
      $buttons.addClass('hidden');
    }
  });

  $category.on('change.select', function() {
    var value = $category.val();
    if (value.length) {
      var new_label_text = $category.find('option:selected').data('label');
      $message.prev().text(new_label_text);
      $message.closest('div.form-group').removeClass('hidden');
      $buttons.removeClass('hidden');
    }
  });
};

var initContact = function() {
  if (!$page.is('.contact')) {
    return;
  }
  $.fn.CommonAjaxCallback = function(data) {
    if (data.reset_fields) {
      $page.find('#m_email, #m_subject, #m_message').val('');
    }
  };
};

var initBlogPost = function() {
  if (!$page.is('.blog-details')) {
    return;
  }
  var __ = AdCampaignDataToCookie();
};

const initProjectSearch = () => {
  if (!$page.is('.project-search')) {
    return;
  }
  const $initial_header = $page.find('.js-initial-header');
  const $query_label = $page.find('.js-search-query-label');
  const $result_count = $page.find('.js-search-result-count');

  $.fn.CommonAjaxCallback = data => {
    $('.read-more-button').pLoader();

    $initial_header.remove();
    $query_label
      .text(`"${data.query}"`)
      .parent()
      .show();
    const result_cnt = data.count;
    const fmt = ngettext(
      '%s ctg-search-cnt-label',
      '%s ctg-search-cnt-label-plural',
      result_cnt,
    );
    const label_text = interpolate(fmt, [result_cnt]);
    $result_count.html(label_text).show();
  };
};

var initProjectEvents = function() {
  if (!$page.is('.project-events')) {
    return;
  }

  initAbuseReportDetails();

  $body.on('click', '.js-change-status', function() {
    var $this = $(this),
      $form = $this.closest('form'),
      $action_input = $form.find('input[name=binder_action]');

    $action_input.val($this.data('action'));
    $form.submit();
  });

  $body.on('click', '.js-submit-on-click', function() {
    $(this).closest('form').submit();
  });

  $body.on('click', '.js-fill-field', function() {
    var $this = $(this);
    $($this.data('target')).val(parseInt($this.data('value')));
    return false;
  });

  $body.on('click', '.js-project-recent-supports', function() {
    var $this = $(this);
    $.get($this.data('target')).done(function(data) {
      $this
        .siblings('.supports-today')
        .removeClass('hidden')
        .find('span')
        .text(data.supports_today);
      $this
        .siblings('.supports-yesterday')
        .removeClass('hidden')
        .find('span')
        .text(data.supports_yesterday);
      $this.remove();
    });
    return false;
  });

  $body.on('click', '#refresh_results', function(e) {
    e.preventDefault();

    pomagamy.close_popup();
    pomagamy.show_popup($(this).attr('href'));
    $.growl({
      title: 'Processing...',
      message: 'Be patient, a new popup will appear soon.',
    });
  });

  $.fn.CommonAjaxCallback = function(data) {
    const c_class = 'hidden';

    if (data.update_binder) {
      if (typeof data.update_binder.requires_contact !== 'undefined') {
        let $elem = $page.find(
          `#binder_${data.update_binder.id} .js-requires-contact`
        );
        if (data.update_binder.requires_contact) {
          $elem.removeClass(c_class);
        } else {
          $elem.addClass(c_class);
        }
      }
    }
  };
};

var initProjectAdsNotifications = function() {
  if (!$page.is('.ads-notifications')) {
    return;
  }

  $body.on('click', '.js-submit-on-click', function() {
    $(this).closest('form').submit();
  });
};

var initUserEvents = function() {
  if (!$page.is('.user-events')) {
    return;
  }

  $body.on('click', '.js-change-status', function() {
    var $this = $(this),
      $form = $this.closest('form'),
      $action_input = $form.find('input[name=event_action]');

    $action_input.val($this.data('action'));
    $form.submit();
  });
};

var initBankAccountReview = function() {
  if (!$page.is('.bank-account-review')) {
    return;
  }

  initAbuseReportDetails();

  $body.on('click', '.js-change-status', function() {
    var $this = $(this),
      $form = $this.closest('form'),
      $action_input = $form.find('input[name=request_action]');

    $action_input.val($this.data('action'));
    $form.submit();
  });

  $body.on('click', '.js-click', function() {
    $($(this).data('target')).click();
    pomagamy.close_popup();
  });
};

var initShareWizard = function() {
  if (!$page.is('.share-wizard')) {
    return;
  }
  var $container = $page.find(
    '.share-wizard-wrapper > .modal-dialog:first-child',
  );

  var changeContainer = function(url) {
    $container = $page.find('.share-wizard-wrapper > .modal-dialog');

    function replaceAfterAjax($base) {
      $.ajax(url, 'get')
        .done(function(data) {
          var $next_container = $(data.html);
          $base.fadeOut(500, function(e) {
            $base.replaceWith($next_container.hide());
            $next_container.fadeIn(1500);
            window.history.replaceState(history.state, document.title, url);
            initContainer($next_container, true);
          });
        })
        .fail(function() {
          window.location.href = url;
        });
    }
    replaceAfterAjax($container);
  };

  var initContainer = function($cont, is_dynamic) {
    function copyTextToClipboard(text) {
      // Copies text to clipboard without displaying an element.
      // From https://stackoverflow.com/a/30810322

      var textArea = document.createElement('textarea');
      // Place in the top-left corner of screen regardless of scroll position.
      textArea.style.position = 'fixed';
      textArea.style.top = 0;
      textArea.style.left = 0;
      // Ensure it has a small width and height. Setting to 1px / 1em
      // doesn't work as this gives a negative w/h on some browsers.
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      // We don't need padding, reducing the size if it does flash render.
      textArea.style.padding = 0;
      // Clean up any borders.
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      // Avoid flash of the white box if rendered for any reason.
      textArea.style.background = 'transparent';
      textArea.value = text;

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand('copy');
        if (successful) {
          $.growl({ title: '', message: gettext('cmn-clipboard') });
        }
      } catch (err) {
        console.log('Clipboard copy error.');
      }
      document.body.removeChild(textArea);
    }

    if (is_dynamic) {
      initAutoSelectText();
      facebookShareDialog($cont.find('.og-share'));
      facebookSendDialog($cont.find('.og-send'));
    }
    if ($cont.find('.js-twt').length) {
      loadTwitter();
    }

    const $whatsapp_shares = $cont.find('.js-wa-share');
    if ($whatsapp_shares.length) {
      whatsAppShare($whatsapp_shares);
    }

    $cont.find('.js-copy-url').on('click', function (e) {
      e.preventDefault();
      const $this = $(this);
      copyTextToClipboard($this.data('target'));
      $this.find('.url').removeClass('hidden');
    });
  };

  // Real init
  initContainer($container, false);

  // next/back button
  $page.on('click', '.js-skip, .js-back', function(e) {
    e.preventDefault();
    changeContainer($(this).attr('href'));
    return false;
  });
};

var initProjectManager = function() {
  if (!$page.is('.manager')) {
    return;
  }
  storeRecentProject();
  loadTwitter();

  initLockedFormScroll();

  const $whatsapp_shares = $page.find('.js-wa-share');
  if ($whatsapp_shares.length) {
    whatsAppShare($whatsapp_shares);
  }

  $.fn.CommonAjaxCallback = function(data) {
    if (data.type === 'anonymize_support') {
      var $elem = $page.find(data.target);
      $elem.find(' > img').attr('src', data.avatar_url);
      $elem.find('.js-anonymize').remove();
      $elem.find('.js-anon-disclaimer').removeClass('hidden');
    } else if (data.type === 'news') {
      pomagamy.show_popup(data.popup_url, function() {
        facebookShareDialog($('.modal-dialog .og-share'));
      });
    } else if (data.type === 'thanks_sent') {
      $page
        .find('#thx_' + data.support_id)
        .find('span')
        .text(data.label)
        .addClass('disabled');
    }
  };
};

var initProjectWithdrawal = function() {
  if (!$page.is('.withdraw')) {
    return;
  }

  storeRecentProject();

  // Init Clipboard plugin
  var copy_btn_selector = '.js-copy';
  if (Clipboard.isSupported()) {
    var clipboard = new Clipboard(copy_btn_selector);
    clipboard.on('success', function(e) {
      $.growl({ title: '', message: gettext('cmn-clipboard') });
      e.clearSelection();
    });
  } else {
    $(copy_btn_selector).remove();
  }

  var $show_verification_btn = $('#js-new-account-show'),
    $verification_info_box = $('#js-new-account-info'),
    $hide_verification_btn = $('#js-new-account-hide');

  function slideElem($elem, $target) {
    $elem.slideUp(animationSpeed, function() {
      $elem.addClass(hiddenClass);
      $target.slideDown(animationSpeed);
    });
  }

  $show_verification_btn.on('click', function() {
    $.post($show_verification_btn.data('url'), { show_info: true })
      .done(function(data) {
        slideElem($show_verification_btn, $verification_info_box);
      })
      .always(function() {
        return false;
      });
    return false;
  });
  $hide_verification_btn.on('click', function() {
    slideElem($verification_info_box, $show_verification_btn);
    return false;
  });

  $('.js-warning-popup').on('click', function(e) {
    if (e.originalEvent) {
      // If user clicked initial button.
      var $ori_btn = $(this);
      var $action_button = $('#warning-popup')
        .find('.popup-footer .btn')
        .last();
      $action_button.off('click').on('click', function() {
        pomagamy.close_popup();
        $ori_btn.trigger('click');
        return false;
      });

      pomagamy.show_popup('#warning-popup');
      return false;
    }
  });

  $.fn.CommonAjaxCallback = function(data) {
    if (data) {
      var $total = $('#total-amount'),
        $total_requested_withdrawal = $('#total-requested-withdrawal-amount'),
        $total_requested_withdrawal_wrapper = $total_requested_withdrawal.closest(
          'li',
        );

      $total.html(data.total);
      $total_requested_withdrawal.html(data.total_requested_withdrawal);
      if ($total_requested_withdrawal_wrapper.hasClass('hidden')) {
        $total_requested_withdrawal_wrapper
          .slideDown(animationSpeed)
          .removeClass('hidden');
      }
      $('.manage-export').removeClass('hidden');
      if (parseFloat(data.total) === 0) {
        $('#payoff-button').remove();
      }
    }
  };
};

var initInvoiceData = function(switcher_selector) {
  var nip_form_sel = '#nip-form',
    $main_container,
    $nip_form,
    $nip_submit_btn,
    $data_form,
    $common_inputs,
    $data_submit_btn;

  if ($page.find(nip_form_sel).length) {
    $main_container = $page.find(nip_form_sel).closest('.modal-dialog');
  } else if ($popups.find(nip_form_sel).length) {
    $main_container = $popups.find(nip_form_sel).closest('.modal-dialog');
  } else {
    return;
  }
  ($data_form = $main_container.find('#invoice-data-form')),
    ($common_inputs = $data_form.find('input[type=text]:not(#id_nip)')),
    ($data_submit_btn = $main_container.find(
      'a[data-target="#' + $data_form.attr('id') + '"]',
    )),
    ($nip_form = $main_container.find(nip_form_sel)),
    ($nip_submit_btn = $data_form.find('#check-nip'));

  function unlock_invoice_form() {
    $data_submit_btn.removeClass('fade no-events');
    $common_inputs.prop('readonly', false);
    $data_form.find('div.disabled').removeClass('disabled');
  }

  function lock_invoice_form() {
    $data_submit_btn.addClass('fade no-events');
    $common_inputs.prop('readonly', true);
    $common_inputs.closest('div').addClass('disabled');
  }

  switcher_selector = switcher_selector || false;
  if (switcher_selector) {
    let $nip_label = $data_form.find('#nip-label');
    let $name_label = $data_form.find("label[for='id_name']");
    let $type = $data_form.find('#id_type');
    let name_label_org = $name_label.data('label-org');
    let name_label_person = $name_label.data('label-person');
    let switcher_active_class = 'bg-danger active';
    let $switch_btn;

    $(switcher_selector)
      .find('a')
      .click(function(e) {
        e.preventDefault();
        removeFormErrors();
        $switch_btn = $(this);
        if ($switch_btn.hasClass(switcher_active_class)) {
          return;
        }

        $switch_btn.siblings().removeClass(switcher_active_class);
        $switch_btn.addClass(switcher_active_class);

        $nip_label.parent().toggleClass('hidden');
        $data_form.find('input[type=text]').val(''); // Clean fields

        if ($switch_btn.data('target') === 'org') {
          $name_label.text(name_label_org);
          $type.val('o');
          lock_invoice_form();
        } else {
          $name_label.text(name_label_person);
          $type.val('p');
          unlock_invoice_form();
        }
      });
  }

  const $conversion_wrapper = $main_container.find('#conversion-wrapper');
  const $main_form_wrapper = $main_container.find('#main-wrapper');
  if ($conversion_wrapper.length) {
    $main_container.find('#anon-logout').on('click', function() {
      $.get($(this).data('target'));
      store.set('perma', false);
      pomagamy.close_popup();
      return false;
    });

    $main_container.find('.js-toggle-conversion').on('click', function() {
      $main_form_wrapper.toggleClass('hidden');
      $conversion_wrapper.toggleClass('hidden');
      $main_container.find('.popup-footer').toggleClass('hidden');
      return false;
    });
  }

  $main_container.on('click', '.target-submit.fade', function(e) {
    return false;
  });

  $nip_submit_btn.on('click', function(e) {
    const nip_value = $data_form.find('#id_nip').val();
    $nip_form.append(
      "<input name='nip' type='hidden' value='" + nip_value + "'>",
    );
    $nip_form.submit();
    $nip_form.find('input').remove();
    return false;
  });

  var current_ajax_callback = $.fn.CommonAjaxCallback;

  $.fn.CommonAjaxCallback = function(data) {
    if (data.nip_details) {
      $.each(data.nip_details, function(key, value) {
        $data_form.find('#id_' + key).val(value);
      });
      unlock_invoice_form();
    } else if (current_ajax_callback) {
      current_ajax_callback(data);
    }
  };
};

var initInvoiceDataSetup = function() {
  if ($page.is('.category-page, .dashboard.settings, .invoices')) {
    $.fn.initNip = initInvoiceData;
  }
};

var initInvoices = function() {
  if (!$page.is('.invoices')) {
    return;
  }
  var form_url = $page.find('#invoice-form').attr('action'),
    popup_selector = '#invoice-confirmation-popup',
    $popup_template = $popups.find(popup_selector),
    $popup_submit_btn = $popup_template.find('.js-get-invoice');

  $('.js-get-invoice').on('click', function(e) {
    var $this = $(this),
      date = $this.attr('data-date'),
      is_new = $this.attr('data-new');

    if (is_new) {
      $popup_submit_btn.attr('data-date', date);
      pomagamy.show_popup(popup_selector);
    } else {
      $popup_submit_btn.prop('disabled', true); // prevents multi-clicks
      $.post(form_url, { date: date }).done(function(data) {
        window.location.href = data.location;
        $popup_submit_btn.attr('data-date', '');
        pomagamy.close_popup();

        var $btn = $("a[data-date='" + date + "']");
        $btn.text(gettext('invc-download'));
        $btn.addClass('btn-outline');
        $btn.removeClass('bg-danger');
        $btn.removeAttr('data-new');
      });
    }

    return false;
  });

  $.fn.CommonAjaxCallback = function(data) {
    $.each(['name', 'address', 'nip'], function(i, e) {
      $popup_template.find('#popup-' + e).text(data[e]);
    });
  };
};

var initMailingSettings = function() {
  if (!$page.is('.mailing-settings')) {
    return;
  }
  $.fn.CommonAjaxCallback = function(data) {
    let $groupWrapper;
    if (data.remove_fields) {
      const groupWrapperSelector = '.js-group-wrapper';
      const rowSelector = '.setting-row';

      data.remove_fields.forEach(fieldname => {
        const $elem = $page.find(`#id_${fieldname}`);
        $groupWrapper = $elem.closest(groupWrapperSelector); // assumes all fields are in the same group
        $elem.closest(rowSelector).remove();
      });
      // Remove empty group wrapper
      if (!$groupWrapper.find(rowSelector).length) $groupWrapper.remove();
    }
  };
};

var initUserList = function() {
  if (!$page.is('.user-list')) {
    return;
  }
  $.fn.CommonAjaxCallback = function(data) {
    if (data.toggle_state) {
      var $btn = $page.find('#user_' + data.toggle_state.id + ' .show-popup');
      if (data.toggle_state.has_account_restrictions) {
        $btn.removeClass('btn-outline').addClass('bg-color-red');
      } else {
        $btn.removeClass('bg-color-red').addClass('btn-outline');
      }
    } else {
      $page.find('.read-more-button').pLoader();
    }
  };
};

var initAdminProjectComments = function() {
  if (!$page.is('.admin-project-comments')) {
    return;
  }
  var $waypoint = $page.find('#waypoint'),
    $form = $page.find('#find-project'),
    $input = $form.find('input');

  $page.on('click', 'a.js-load-comments', function(e) {
    e.preventDefault();
    $input.val($(this).data('slug'));
    $form.submit();
    pomagamy.scrollToElem($waypoint);
  });

  $.fn.CommonAjaxCallback = function(data) {
    $page.find('.read-more-button').pLoader();
  };
};

var initAdminProjectNews = function() {
  if (!$page.is('.admin-project-news')) {
    return;
  }
  $page.on('click', '.js-update-status', function(e) {
    e.preventDefault();
    var $this = $(this),
      $form = $this.closest('form');
    $form.find('input').val($this.data('status'));
    $form.submit();
  });
};

var initAdminSupportEdit = function() {
  if (!$page.is('.support-override, .support-refund, .project-refund, .support-resurrect')) {
    return;
  }
  $.fn.CommonAjaxCallback = function(data) {
    if (data.popup_body) {
      var $wrapper = $popups.find('#bs-limbo');
      $wrapper.append($(data.popup_body));
      pomagamy.show_popup('#confirmation-popup');
    } else if (data.reset_inputs) {
      $page.find('#find-support').trigger('reset');
    }
  };
};

var initAdminSupportFinder = function() {
  if (!$page.is('.admin-find-supports')) {
    return;
  }
  var class_on = 'icon-oko_otwarte',
    class_off = 'icon-oko_zamkniete';
  $.fn.CommonAjaxCallback = function(data) {
    $page
      .find('#anon_' + data.support_id)
      .removeClass(data.is_public ? class_off : class_on)
      .addClass(data.is_public ? class_on : class_off);
  };
};

var initMenu = function() {
  var $headerUser = $('#header-user'),
    $profileSidebar = $('#profile-sidebar');
  if (!($headerUser.length && $profileSidebar.length)) {
    return false;
  }

  var $logout_button = $html.find('#logout-button'),
    page = $profileSidebar.data('dashboard-page'),
    next = $profileSidebar.data('next'),
    show_settings = $profileSidebar.find('input').data('settings') === 'True';

  if (typeof next !== typeof undefined && next !== false) {
    $logout_button.attr(
      'href',
      $logout_button.attr('href') + '?next=' + encodeURIComponent(next),
    );
  }
  if (page) {
    $profileSidebar.find('#menu_' + page).addClass('active');
  }
  if (!show_settings) {
    $profileSidebar.find('#menu_settings').remove();
  }
  userSidebar($headerUser, $profileSidebar);
};
var initAdminToolsBar = function() {
  var $adm_tools = $('.admin-tools');
  if (!$adm_tools.length) {
    return;
  }
  $adm_tools.detach().appendTo('body');
  $('body').append("<div class='admin-overlay admin-tools-show-button'></div>");

  function toggleTools() {
    $('.admin-tools,.admin-overlay').toggleClass('opened');
  }

  $('.admin-tools-show-button').on('click', toggleTools);
  $adm_tools.on('click', '.show-popup', toggleTools);
};

var initCustomProjectReport = function() {
  if (!$page.is('.custom-project-report')) {
    return;
  }

  const $form = $page.find('#sections-form');
  const related_fields = {
    'owner_info': ['owner_name', 'owner_email', 'owner_misc'],
    'withdrawals': ['withdrawal_accounts', 'withdrawal_history', 'withdrawal_summary', 'withdrawal_summary_fees'],
    'withdrawal_summary': ['withdrawal_summary_fees'],
    'supports': ['supports_name', 'supports_email'],
  }
  const checked_event = 'pr-switch-checked';

  for ( const [main_field, subfields] of Object.entries( related_fields ) ) {
    const $main_field = $form.find(`#id_${main_field}`);
    $main_field.on('click.pr', function() {
      let status = $(this).is(':checked');
      subfields.forEach(
        subfield => {
          $form.find(`#id_${subfield}`).trigger(checked_event, status);
        });
    });

    $form.on('click', subfields.map(
      function(value) {
        return `#id_${value}`;
      },
    ).join(', '), function() {
      if ($(this).is(':checked') && !$main_field.is(':checked')) {
        $main_field.trigger(checked_event, true);
      }
    });
  }

  const $disclaimer = $page.find('#id_disclaimer');
  const $disclaimers = $page.find('.js-disclaimers');
  const disclaimer_selector = '.js-title';
  const default_disclaimer_selector = '.js-default';

  function copyDisclaimer($obj) {
    const title = `<h4>${$obj.text()}</h4>`;
    const message = `<div>${$obj.next().data('text')}</div>`;
    $disclaimer[0].value += `\n${title}\n${message}\n`;
  }

  $disclaimers.on('click', disclaimer_selector, function(){
    copyDisclaimer($(this));
  });
  // auto populate textarea with default disclaimer sections
  $disclaimers.find(default_disclaimer_selector).trigger('click');

  $('.js-select-all').on('click', function(e){
    e.preventDefault();
    $disclaimer.val('');
    $disclaimers.find(disclaimer_selector).trigger('click');
  });
  $('.js-deselect-all').on('click', function(e){
    e.preventDefault();
    $disclaimer.val('');
  });
  $('.js-set-defaults').on('click', function(e){
    e.preventDefault();
    $disclaimer.val('');
    $disclaimers.find(default_disclaimer_selector).trigger('click');
  });

};

// CategoryTagPage helpers
var stickyElem = function() {
  var elemSelector = '.js-stickable',
    $elem = $(elemSelector),
    elemOffset,
    $nextItem;

  if (!$elem.length) {
    return;
  }

  setTimeout(function() {
    elemOffset = $elem.offset().top;
  }, 1000);

  // Workaround for iOS devices (scroll event is broken so we have to use position:sticky)
  if ($elem.is('.js-mac-sticky')) {
    if ($body.is('.mac')) {
      $elem.removeClass('js-stickable');
      return;
    } else {
      $elem.removeClass('js-mac-sticky');
    }
  }

  $(window).scroll(function() {
    const windowScrollTop = $(window).scrollTop();

    if (windowScrollTop > elemOffset) {
      if ($elem.hasClass('sticked')) {
        return;
      }
      $elem.addClass('sticked');
    } else {
      $elem.removeClass('sticked');
    }
  });
};

var lockOtherForms = function($wrapper, $target) {
  $wrapper.find('.open-form').addClass('disabled');
  $wrapper.find('.remove-element').addClass('disabled');
  $wrapper.find('li').addClass('js_disabled');
  $wrapper.find('input').prop('disabled', true);
  $wrapper.find('.js-lock-on-form').addClass('disabled');

  var $target_wrapper = $target.closest('.js-lock-on-form.disabled');
  $target_wrapper.removeClass('disabled');
  $target_wrapper.prev('.circle-steps, .title').removeClass('disabled');
};
var unlockOtherForms = function($wrapper) {
  // unlock disabled Add/Edit buttons
  $wrapper.find('.open-form').removeClass('disabled');
  $wrapper.find('.remove-element').removeClass('disabled');
  $wrapper.find('li').removeClass('js_disabled');
  $wrapper.find('input').prop('disabled', false);
  $wrapper
    .find('.js-lock-on-form')
    .not('.perm')
    .removeClass('disabled');
};

var initOrgSignup = function() {
  if (!$page.is('.org-signup')) {
    return;
  }
  if ($page.is('.step-2')) {
    initInvoiceData();
  }
};

var initFullpageWithPopup = function() {
  if (!$page.is('.fullpage-with-popup')) {
    return;
  }
  if ($page.is('.pwd-reset')) {
    store.set('perma', true);
    $.fn.CommonAjaxCallback = function(data) {
      store.set('perma', false);
      pomagamy.close_popup();
    };
  }
  $(document).on('hidden.bs.modal', function() {
    $body.find('.share-wizard-wrapper').css('opacity', 1);
    $(document).off('hidden.bs.modal');
  });
};

var init404 = function() {
  if (!$page.is('.error404')) {
    return;
  }
  // The whole response is cached, so csrf has to be read from cookie.
  $page.find('#csrf-token').val(getCookie('csrftoken'));
}

let initBottomBarExperiment2 = function() {
  // Experiment Bottom Bar #2
  // Button "Stwórz zbiórkę - za darmo" which redirects to Project creator
  // Variant A: Default color (white)
  // Variant B: Orange color
  // Variant C: Green color

  const expPrefix = 'exp_bba_2';

  let $bottomFixedBar = $page.find('#section .bottom-fixed-bar');
  if (!$bottomFixedBar.length) {
    return;
  }

  const expVariant = getCookie(expPrefix);
  if (!expVariant) {
    const variantOptions = ['A', 'B', 'C']
    const expVariant = variantOptions[Math.floor((Math.random()*variantOptions.length))];
    setCookie(expPrefix, expVariant, 365);
  }

  function getBackgroundColorClass(variant) {
    if (variant === 'A') return '';  // keep original bg-color
    if (variant === 'B') return 'bg-color-orange';
    if (variant === 'C') return 'bg-color-green';
    return '';
  }

  $bottomFixedBar.addClass(getBackgroundColorClass(expVariant));

  let $btn = $bottomFixedBar.find(`a.${expPrefix}`);
  $btn.addClass(getBackgroundColorClass(expVariant));

  // Add bg color variant in href src, set proper id
  const oldHref = $btn.attr('href');
  const variantId = expPrefix + '_' + expVariant;
  $btn.attr('href', `${oldHref}?src=${variantId}`);
  $btn.attr('id', variantId);
  $btn.addClass('show');

  $btn.on('click', function(e) {
    // Dumb delay to improve odds of GTM catching click event.
    // This is bad and should be done by sending a GA event,
    // but atm. no-one knows how to configure GTM+GA to handle it.
    e.preventDefault();
    setTimeout(function() {
      window.location.href = e.target.getAttribute('href');
    }, 150);
  });
};

var init = function() {
  var $scrollMenu = $('.fixed-inside-menu'),
    $scrollButtons = $('.fixed-inside-buttons'),
    $facebookShareDialog = $('.og-share'),
    $facebookSendDialog = $('.og-send'),
    $showPopupOnLoad = $('.show-popup-on-load'),
    $form_errors = $(
      '.form-error-wrapper span, .field-error-wrapper span, .error, .error-msg, .error-msg-form',
    );

  checkUnsupportedBrowsers();

  checkWindows();
  checkMac();
  checkLinux();
  checkFacebookApp();

  errorStatus();

  initCookiesBox();
  //initPPBox()  //Disabled for now.
  checkAdblock();

  // Payment page form has to be cleared before initializing inputs.
  if ($page.is('.payment')) {
    $('form')
      .get(0)
      .reset();
  }

  init_inputs();

  do_csrf_magical_stuff();

  checkStatus();
  initCheckStatus();

  const trackingCookieMaxAge = 400; // days, max value accepted by modern browsers
  const trackingCookieName = 'vtc';
  setTrackingCookie(trackingCookieName, trackingCookieMaxAge);

  init_fb_account_name();

  if ($form_errors.length) {
    pomagamy.scrollToElem($form_errors.eq(0));
  }

  if (!mobile) {
    $('form')
      .on('focus', 'input[type=number]', function() {
        $(this).on('mousewheel.disableScroll', function(e) {
          e.preventDefault();
        });
      })
      .on('blur', 'input[type=number]', function() {
        $(this).off('mousewheel.disableScroll');
      });
  }

  if ($page.is('.tag-page')) {
    stickyElem();
  }
  if ($page.is('.category-page')) {
    menuChangeTheme(
      '.category-menu-card.js-trending',
      '.trending-projects-container:last',
      '.trending-projects-wrapper',
      'change_trending_category',
    );
    stickyElem();

    window.onload = () => {
      if (window.location.href.indexOf('?ln') !== -1) {
        pomagamy.scrollToElem($page.find('.new-project-list-section:first'));
      }
    };

    const scene = document.querySelector('.img-container');
    if (scene) {
      new Parallax(scene, {
        scalarX: 5,
        scalarY: 5,
        relativeInput: true,
        hoverOnly: false,
      });
    }
    const icons = document.querySelectorAll('.img-wrap');
    if (icons) {
      ScrollReveal().reveal(icons, {
        interval: 24,
        reset: false,
        distance: '100px',
        scale: 0.7,
        viewOffset: {
          bottom: 150,
        },
      });
    }

    const logoEl = document.querySelector('.header-logo');
    if (logoEl) {
      const rect = logoEl.getBoundingClientRect();
      ['scroll', 'load', 'resize'].forEach(event => {
        window.addEventListener(event, ev => {
          const scrollTopWindow =
            window.pageYOffset || document.documentElement.scrollTop;

          if (scrollTopWindow > rect.top) {
            if (!$(document.body).hasClass('past-logo')) {
              document.body.classList.add('past-logo');
            }
          } else {
            if ($(document.body).hasClass('past-logo')) {
              document.body.classList.remove('past-logo');
            }
          }
        });
      });
    }

    if ($page.is('.special-event')) {
      initHighestSupportsList();

      var $event_counter = $page.find('#event-countdown');
      if ($event_counter.length) {
        dateCountdownTimer($event_counter);
      }
    }

    $page.on('click', '.js-scroll-form', function(e) {
      e.preventDefault();
      pomagamy.scrollToElem($page.find('#section'));
    });
  }

  // InvoiceDataSetup needs to be initiated BEFORE automatic popups!
  initInvoiceDataSetup();

  initFullpageWithPopup();

  initLazyEmbeds();

  initAsyncImports();

  // handle ajax submit, used in conjunction with UltimateAjaxMixin
  $(document).on('submit', 'form.ajax', function(e) {
    var $form = $(this);

    // Used for admin tools views, such as binder or withdrawal requests.
    // Generally all paginated pages with forms.
    // Prevents form submitting if another form is already being processed.
    let $lockOnSubmitWrapper = $page.find('.js-lock-on-submit-wrapper');

    // Locks entire form on submit, preventing multiple submits.
    const isSubmitLocked = antiRageClick($form);
    if (isSubmitLocked) {
      return false;
    }

    e.preventDefault();
    e.stopPropagation();

    var target = $form.data('target'),
      $target = $(target),
      mode = $form.data('mode'),
      keep_target = $form.data('keep-target'),
      $paginator_button = $($form.data('paginator-button'));

    if ($form.hasClass(lock_class)) {
      return false;
    } else {
      $form.addClass(lock_class);
      if ($lockOnSubmitWrapper) toggleLockFormsOnSubmit($lockOnSubmitWrapper, true);
    }

    removeFormErrors();

    var data = $form.serializeArray();

    $.ajax({
      method: 'post',
      url: $form.attr('action'),
      data: data,
    })
      .done(function(data, status, xhr) {
        // src: https://gist.github.com/jasonweng/393aef0c05c425d8dcfdb2fc1a8188e5
        if (xhr.getResponseHeader('Content-Disposition')) {
          // check for a filename
          var filename = "";
          var disposition = xhr.getResponseHeader('Content-Disposition');
          if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
          }

          var type = xhr.getResponseHeader('Content-Type');
          var blob = new Blob([data], {type: type});

          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
            window.navigator.msSaveBlob(blob, filename);
          } else {
            var URL = window.URL || window.webkitURL;
            var downloadUrl = URL.createObjectURL(blob);

            if (filename) {
              // use HTML5 a[download] attribute to specify filename
              var a = document.createElement("a");
              // safari doesn't support this yet
              if (typeof a.download === 'undefined') {
                window.location = downloadUrl;
              } else {
                a.href = downloadUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
              }
            } else {
              window.location = downloadUrl;
            }

            setTimeout(function () {
              URL.revokeObjectURL(downloadUrl);
            }, 100); // cleanup
          }

        }

        if (data.success_msg) {
          $.growl({
            title: gettext('notif-title-ready'),
            message: data.success_msg,
          });
        }
        // Push Google Analytics events (datalayer is used because of GTM):
        if (data.ga_event && typeof window.dataLayer !== 'undefined') {
          if (data.location) {
            data.ga_event.eventCallback = function() {
              window.location.href = data.location;
            };
            data.ga_event.eventTimeout = 1500;
          }
          window.dataLayer.push(data.ga_event);
        }

        if (enable_beforeunload_message) {
          // Reset serialized form data
          opened_form_initial_data = {};
          $opened_form = false;
        }

        if (data.location) {
          if (data.in_new_window) {
            window.open(data.location);
          } else {
            window.location.href = data.location;
          }
          return;
        }

        if (data.replace_text) {
          jQuery.each(data.replace_text, function(index, value) {
            var $elem = $(value.selector);
            $elem.fadeOut(animationSpeed, function() {
              $elem.html(value.value).fadeIn(animationSpeed);
            });
          });
        }

        if (data.replace_html) {
          jQuery.each(data.replace_html, function(index, value) {
            $(value.selector).replaceWith(value.html);
          });
        }

        if (data.replace_attr) {
          jQuery.each(data.replace_attr, function(index, value) {
            $(value.selector).attr(value.attr, value.value);
          });
        }

        if (data.replace_location) {
          window.history.replaceState(
            history.state,
            document.title,
            data.replace_location,
          );
        }

        if (data.move) {
          jQuery.each(data.move, function(index, value) {
            $(value.selector)
              .detach()
              .appendTo(value.target);
          });
        }

        if (data.set_value) {
          $(data.set_value.selector).val(data.set_value.value);
        }

        // Regenerating paginators are usually used when one of the elements needs to be deleted.
        function regeneratePaginator() {
          if (
            $paginator_button.length &&
            $paginator_button.not('.fully-loaded') &&
            data.altered_paginator_state
          ) {
            $paginator_button.addClass('state-update');
            $paginator_button.triggerHandler('click');
          }
        }

        if (mode === 'delete_anim') {
          $target.slideUp(animationSpeed, function() {
            // Don't user $target, as it may interfere with target override in the further part.
            $(this).remove();
            regeneratePaginator();
          });
        } else {
          // Just in case if regenerating paginator would be used without delete_anim in delete form.
          regeneratePaginator();
        }

        if (data.html) {
          var $data = $(data.html);

          init_inputs($data);

          if (data.mode) {
            mode = data.mode;
          }

          if (data.target) {
            target = data.target;
            $target = $(target);
          }

          if (data.keep_target) {
            keep_target = data.keep_target;
          }

          if (target === 'popup') {
            $popups.find('.modal.show').html($data.find('.modal-dialog'));
          } else if (mode === 'append') {
            $target.append($data);
            // Used for adding, when target is also connected with a paginator.
            if (
              $paginator_button.length &&
              !$paginator_button.hasClass('fully-loaded')
            ) {
              if ($target.is('ul')) {
                $target.find('li:first-child').remove();
              } else if ($target.is('div')) {
                $target.find('div:first-child').remove();
              }
            }
          } else if (mode === 'replace_anim') {
            // Crop mode replaces target with loaded content (after preaload of img if exists)
            $target.slideUp(animationSpeed, function() {
              var $that = $(this),
                $parent = $that.parent(),
                $img = $data.find('img'),
                replaceContent = function() {
                  if (keep_target) {
                    $that.after($data);
                  } else {
                    $that.replaceWith($data);
                  }

                  setTimeout(function() {
                    $parent
                      .find('.' + hiddenClass)
                      .slideDown(animationSpeed)
                      .removeClass(hiddenClass);
                  }, 50);
                };

              if (
                $img.length &&
                !$data.find("[src^='data:image/png;base64,']").length
              ) {
                getAllImagesDonePromise($img).then(function() {
                  replaceContent();
                });
              } else {
                replaceContent();
              }
            });
          } else {
            $target.html($data);
          }
        }

        if (data.close_popup) {
          pomagamy.close_popup();
        }

        if (data.popup_url) {
          pomagamy.show_popup(data.popup_url);
        }

        if (
          (data.callback_data || $form.is('#crop-form')) &&
          $.fn.CommonAjaxCallback
        ) {

          $.fn.CommonAjaxCallback(data.callback_data);
        }

        if ($form.is('#set-language-form')) {
          window.location.reload();
        }
      })
      .fail(function(data) {
        if (data.status === 400) {
          if (data.responseJSON.error_popup_url) {
            pomagamy.show_popup(data.responseJSON.error_popup_url);
          }

          if (data.responseJSON.error_msg) {
            $.growl.error({
              title: gettext('cmn-error'),
              message: data.responseJSON.error_msg,
            });
          }

          appendFormErrors(data.responseJSON);

          if (data.responseJSON.location) {
            window.location.href = data.responseJSON.location;
          }
        } else {
          console.error('Something went wrong...');
        }
        if ($.fn.CommonAjaxError) {
          $.fn.CommonAjaxError(data);
        }
      })
      .always(function() {
        $form.removeClass(lock_class);
        if ($lockOnSubmitWrapper) toggleLockFormsOnSubmit($lockOnSubmitWrapper, false);
        var $submit_button = $form.find('input[type=submit]');

        if (!$submit_button.length) {
          $submit_button = $(
            '.target-submit[data-target="#' + $form.attr('id') + '"]',
          );
        }

        if ($submit_button.length) {
          $submit_button.fadeTo(300, 1, function() {
            $submit_button
              .removeClass('disabled')
              .removeClass('animating')
              .parent()
              .removeClass('loading');
          });
        }
      });
  });

  $(document).on('submit', 'form.dynamic', function(e) {
    e.preventDefault();
    e.stopPropagation();

    var $form = $(this),
      $target = $($form.data('target')),
      $mode = $form.data('mode'),
      $hiddenElem = $form.closest('.' + activeClass).prev(),
      $wrapper = $form.closest('.dynamic-form-wrapper');
    if ($form.hasClass(lock_class)) {
      return false;
    } else {
      $form.addClass(lock_class);
    }

    removeFormErrors();

    var data = $form.serializeArray();
    //Ajax do formularza
    $.ajax({
      url: $form.attr('action'),
      method: 'post',
      data: data,
    })
      .done(function(data) {
        if (data.success_msg) {
          $.growl({
            title: gettext('notif-title-ready'),
            message: data.success_msg,
          });
        }
        unlockOtherForms($wrapper);

        if (enable_beforeunload_message) {
          // Reset serialized form data
          opened_form_initial_data = {};
          $opened_form = false;
        }

        if (data.replace_text) {
          jQuery.each(data.replace_text, function(index, value) {
            var $elem = $(value.selector);
            $elem.fadeOut(animationSpeed, function() {
              $elem.html(value.value).fadeIn(animationSpeed);
            });
          });
        }

        if (data.replace_attr) {
          jQuery.each(data.replace_attr, function(index, value) {
            $(value.selector).attr(value.attr, value.value);
          });
        }

        if ($target.length) {
          // Special case for ProjectManager / News
          $form.closest('.' + activeClass).slideUp(animationSpeed, function() {
            var $that = $(this),
              $html = $(data.html);

            if ($mode === 'replace') {
              $target.replaceWith($html);
            } else {
              $target.append($html); // Unused atm
            }

            // project manager news
            if (data.news_header) {
              $that
                .prev()
                .prev()
                .find('.news-header')
                .text(data.news_header);
            }

            init_inputs($html);

            $html.find('.read-more-button').pLoader();

            $hiddenElem.slideDown(animationSpeed).removeClass(hiddenClass);
            $html.slideDown(animationSpeed).removeClass(hiddenClass);

            $that.remove();
          });
        } else {
          //zwijamy formularz
          $form.closest('.' + activeClass).slideUp(animationSpeed, function() {
            var $that = $(this),
              $parent = $hiddenElem.parent(),
              $data = $(data.html),
              $img = $data.find('img');

            function replaceContent() {
              //wrzucamy do doma element zaciagniety i go rozwijamy
              $hiddenElem.replaceWith($data);
              $parent
                .find('.' + hiddenClass)
                .slideDown(animationSpeed)
                .removeClass(hiddenClass);
            }

            $that.remove();

            if (
              $img.length &&
              !$data.find("[src^='data:image/png;base64,']").length
            ) {
              getAllImagesDonePromise($img).then(function() {
                replaceContent();
              });
            } else {
              replaceContent();
            }
          });
        }
        if (data.callback_data && $.fn.CommonAjaxCallback) {
          $.fn.CommonAjaxCallback(data.callback_data);
        }
        // Push Google Analytics events (datalayer is used because of GTM):
        if (data.ga_event && typeof dataLayer !== 'undefined') {
          dataLayer.push(data.ga_event);
        }
      })
      .fail(function(data) {
        if (data.status === 400) {
          if (data.responseJSON.error_msg) {
            $.growl.error({
              title: gettext('cmn-error'),
              message: resp.responseJSON.error_msg,
            });
          }

          if (data.responseJSON.error_popup_url) {
            pomagamy.show_popup(data.responseJSON.error_popup_url);
          }

          appendFormErrors(data.responseJSON);
        } else {
          console.error('Something went wrong...');
        }
      })
      .always(function() {
        $form.removeClass(lock_class);

        var $submit_button = $form.find('input[type=submit]');

        if (!$submit_button.length) {
          $submit_button = $(
            '.target-submit[data-target="#' + $form.attr('id') + '"]',
          );
        }

        if ($submit_button.length) {
          $submit_button.fadeTo(300, 1, function() {
            $submit_button
              .removeClass('disabled animating')
              .parent()
              .removeClass('loading');
          });
        }
      });
  });

  $(document).on('click', 'a.load-in-container', function(e) {
    e.preventDefault();
    e.stopPropagation();

    var $this = $(this),
      url = $this.attr('href'),
      $target = $($this.data('target')),
      type = $this.data('type'),
      mode = $this.data('mode');

    if ($this.hasClass('disabled')) {
      return;
    }

    ajax = $.ajax({
      url: url,
      type: 'GET',
      data: { type: type },
    });
    ajax.done(function(response) {
      var $content = $(response.html);

      // for conversations
      $('ul#true-conversation-list')
        .find('li.active')
        .removeClass('active');
      $this.parent().addClass('active');
      $this.parent().removeClass('new-item');

      if (mode === 'append') {
        $content.hide();
        $target.append($content);
        $content.slideDown(animationSpeed);
      } else {
        $target.html($content);
      }

      checkStatus($content);
      init_inputs($content);
      $content.find('.read-more-button').pLoader();
      initMarkUnread($content);

      initConversationList($content);
    });
    ajax.fail(function(response) {
      console.error('error');
    });
  });

  $(document).on('click', '.open-form', function(e) {
    e.preventDefault();
    e.stopPropagation();

    var $this = $(this),
      url = $this.attr('href'),
      $target = $this.closest($this.data('target')),
      $wrapper = $this.closest('.dynamic-form-wrapper');

    if ($this.hasClass('disabled')) {
      return;
    }

    //ajax po formularz który się pojawi
    ajax = $.ajax({
      url: url,
      dataType: 'html',
    });

    ajax.done(function(response) {
      lockOtherForms($wrapper, $target);

      //dorzucamy element pod naszym targetem
      var $response = $(response);
      $target.after($response);

      $response.hide();

      checkStatus($response);
      init_inputs($response);

      $response
        .find('form')
        .parent('.container-form.open')
        .hide();

      if (enable_beforeunload_message) {
        //save initial form data
        $opened_form = $response.find('form');
        opened_form_initial_data = $opened_form.serialize();
      }

      // init word counter
      var cnt = $response.find('.show-counter');
      if (cnt.length) {
        cnt.pomagamyCounter();
      }

      //Zwijamy element który klikneliśmy
      $target.slideUp(animationSpeed, function() {
        $(this).addClass(hiddenClass);

        //rozwijamy pobrany element
        $target.next().slideDown(animationSpeed);
        pomagamy.scrollToElem($target.next());
      });
      setTimeout(function() {
        $(window).trigger('resize.pfi');
      }, 300);
    });

    ajax.fail(function(response) {
      console.error('error');
    });

    e.preventDefault();
  });

  $(document).on('click', '.close-form', function(e) {
    var $this = $(this),
      $closed = $this.closest('.' + activeClass),
      $hiddenElem = $closed.prev(),
      $wrapper = $this.closest('.dynamic-form-wrapper');

    //zwijamy formularz i ustwiamy odpowiednie klasy
    $closed.slideUp(animationSpeed * 1.5, function() {
      $closed.remove();
      $hiddenElem.slideDown(animationSpeed).removeClass(hiddenClass);
      if (!$hiddenElem.is('.' + activeClass)) {
        // form-in-form edge case
        unlockOtherForms($wrapper);
      }
    });

    if (enable_beforeunload_message) {
      // Reset serialized form data
      opened_form_initial_data = {};
      $opened_form = false;
    }
    $(window).trigger('resize.pfi');

    e.preventDefault();
  });

  $(document).on('click', '.remove-element-simple', function(e) {
    var $this = $(this);
    if ($this.hasClass('disabled')) {
      return false;
    }

    e.preventDefault();
    e.stopPropagation();

    var url = $this.attr('href'),
      $target = $this.closest($this.data('target'));

    $.post(url, {}).done(function(data) {
      var $html = $(data.html);
      if ($html.length) {
        $target.replaceWith($html);
      } else {
        $target.remove();
      }
      if (data.replace_attr) {
        jQuery.each(data.replace_attr, function(index, value) {
          $(value.selector).attr(value.attr, value.value);
        });
      }
    });
  });

  $(document).on('click', '.remove-element', function(e) {
    var $this = $(this);
    if ($this.hasClass('disabled')) {
      return false;
    }

    e.preventDefault();
    e.stopPropagation();

    var hiddenClass = 'hidden-by-dynamic';

    var url = $this.data('url');
    if (!url) {
      url = $this.attr('href');
    }
    var $target = $this.closest($this.data('target')),
      $paginator_button = $($this.data('paginator-button')),
      $wrapper = $this.closest('.dynamic-form-wrapper'),
      data = {};

    if ($paginator_button.length) {
      var has_more =
        $paginator_button.length > 0 &&
        !$paginator_button.hasClass('fully-loaded');
      data = { render_html: has_more };
    }

    if (url) {
      $.post(url, data).done(function(data) {
        if ($paginator_button.length && data.html) {
          $target = $this.closest($this.data('paginator-wrapper-target'));
          var $content = $(data.html),
            $img = $content.find('img'),
            replaceContent = function() {
              $content.find('.read-more-button').pLoader();
              $target.slideUp(animationSpeed, function() {
                var $parent = $target.parent();
                $target.replaceWith($content);
                setTimeout(function() {
                  $parent
                    .find('.' + hiddenClass)
                    .slideDown(animationSpeed)
                    .removeClass(hiddenClass);
                }, 50);
              });
            };
          if (
            $img.length &&
            !$content.find("[src^='data:image/png;base64,']").length
          ) {
            getAllImagesDonePromise($img).then(function() {
              replaceContent();
            });
          } else {
            replaceContent();
          }
          return false;
        }

        if (data.html) {
          $target.slideUp(animationSpeed, function() {
            var $parent = $target.parent();
            $target.replaceWith(data.html);
            setTimeout(function() {
              $parent
                .find('.' + hiddenClass)
                .slideDown(animationSpeed)
                .removeClass(hiddenClass);
            }, 50);
          });
        } else {
          $target.slideUp(animationSpeed, function() {
            $target.remove();
          });
        }

        $wrapper.find('.open-form').removeClass('disabled');
        $wrapper.find('.remove-element').removeClass('disabled');
        $wrapper.find('li').removeClass('js_disabled');
        $wrapper.find('input').prop('disabled', false);

        // Allow to unlock other forms.
        if (data.unlock_form) {
          unlockOtherForms($(data.unlock_form));
        }
      });
    } else {
      $target.slideUp(animationSpeed, function() {
        $(this).remove();
      });
    }
  });

  if ($showPopupOnLoad.length) {
    var target = $showPopupOnLoad.data('target'),
      show_on_mobile = $showPopupOnLoad.data('mobile');
    if (!(mobile && show_on_mobile === 'disabled')) {
      setTimeout(function() {
        pomagamy.show_popup(target);
      }, 150);
    }
  }

  if ($scrollMenu.length) {
    scrollMenu($scrollMenu);
  }
  if ($scrollButtons.length) {
    $scrollButtons.pomagamyFixedInside({
      stickHelper: $('#button-bottom'),
      offsetTopAdjustment: 200,
    });
  }

  if ($facebookShareDialog.length) {
    facebookShareDialog($facebookShareDialog);
  }
  if ($facebookSendDialog.length) {
    facebookSendDialog($facebookSendDialog);
  }

  initCloseMessage();

  if ($('.tabs-switcher').length) {
    tabsSwitcher('.switcher');
  }

  // --- Load whole pages ---

  initProjectSearch();
  initProjectDetails();

  // Project Wizard
  initProjectWizardBasicInfo();
  initProjectWizard();
  initShareWizard();

  // Support Wizard
  initPayment();
  initProjectComment();
  initAutoSelectText();

  // Manager
  initProjectManager();
  initProjectWithdrawal();

  // Conversations
  initConversationList();
  initMarkUnread();

  // Dashboard
  initInvoices();
  initMailingSettings();

  initProjectEvents();
  initProjectAdsNotifications();
  initUserEvents();
  initBankAccountReview();
  initUserList();
  initAdminProjectComments();
  initAdminSupportFinder();
  initAdminSupportEdit();
  initAdminProjectNews();

  initCustomProjectReport();

  initTOS();
  initFaq();
  initAbuseReport();
  initContact();
  initOnePercent();

  initBlogPost();

  initOrgSignup();

  init404();

  initPopovers();

  // ------------------------------------------------

  registrationBgFix();

  if ($page.find('a.read-more-button').length) {
    $('a.read-more-button').pLoader();
  }

  if ($page.find('a.js-change-lang').length) {
    $page.find('a.js-change-lang').on('click', function(e) {
      window.location.href = $(this).data('url') + window.location.search
      return;
    });
  }

  if ($page.find('a.mobile-read-more-button').length) {
    $page.on('click', '.mobile-read-more-button', function(e) {
      var $this = $(this),
        related_class = $this.is('.js-include-v-tablets')
          ? 'hidden-md-down'
          : 'hidden-sm-down';
      // related class is used to cover edge case on CategoryPage.
      $this
        .closest('.button-group')
        .parent()
        .find('.' + related_class)
        .removeClass(related_class);
      $this.parent().remove();
      return false;
    });
  }

  //colorbox
  $body.on('click', '.popup a.close', function(e) {
    pomagamy.close_popup();
    e.preventDefault();
  });

  //picture picker
  $body.on('touchstart click', '.picture-picker ul li', function() {
    var $this = $(this),
      $radio = $this.find(':radio');

    $this
      .parent('ul')
      .find('li')
      .removeClass('active');
    $this.addClass('active');

    $('.picture-picker')
      .find('.radio, :radio')
      .removeClass('.radio-checked')
      .removeAttr('checked');
    $radio.prop('checked', 'checked');
    $radio.parent().addClass('radio-checked');
  });

  initMenu();
  initAdminToolsBar();

  // really disable disabled items
  $(document).on('click', '.disabled', function(e) {
    e.preventDefault();
    e.stopPropagation();
    return false;
  });

  $('.history-back').click(function(e) {
    e.preventDefault();
    window.history.back();
  });

  // launch colorbox
  $(document).on('click', '.show-popup', function(e) {
    var $this = $(this);
    if ($this.hasClass('disabled')) {
      return false;
    }
    e.preventDefault();

    const scrollTo = $this.data('scroll-to');
    let onloadExtraCallback;
    // Scroll to section feature.
    if (scrollTo) {
      const maxRetries = 10;

      onloadExtraCallback = function(retryCount = 0) {
        const $elem = $body.find(scrollTo);
        if ($elem.is(':visible')) {
          $elem.get(0).scrollIntoView(); // retrieve JS DOM element
        } else if (retryCount < maxRetries) {
          setTimeout(() => onloadExtraCallback(retryCount + 1), 200); // Check every 200ms
        }
      };
    }
    pomagamy.show_popup($this.data('target'), onloadExtraCallback);
    $('.js-popover').popover('hide'); // hide all opened popovers
  });

  // Change
  $(document).on('click', 'a.in-popup', function(e) {
    e.preventDefault();
    var url = $(this).attr('href'),
      $popup = $popups.find('.modal.show');

    $.ajax(url, 'get').done(function(data) {
      // Handle allauth views
      if (data.html) {
        data = data.html;
      }
      var $data = $(data);
      init_inputs($data);
      $popup.html($data.find('.modal-dialog'));
    });
  });

  // submit chosen form
  $(document).on('click', '.target-submit', function(e) {
    e.preventDefault();
    e.stopPropagation();
    var $this = $(this);
    if ($this.hasClass('disabled')) {
      return false;
    }

    var form = $($this.data('target'));
    // handle temporary rage click lock class
    if (form.hasClass(RAGE_LOCK_CLASS)) {
      return false;
    }
    $this.addClass('disabled');
    form.submit();
  });

  $(document).on('click', '.show-crop', function(e) {
    e.preventDefault();
    e.stopPropagation();

    var file_field = $(this).data('file-field');

    if (file_field) {
      $(file_field).click();
    } else {
      $('.crop-image-file-field').click();
    }
  });

  $('.waypoint-container').on('click', 'a[href^="#"]', function(e) {
    // scroll to content
    var $target = $('#' + this.href.split('#')[1]);
    if ($target.length) {
      e.stopPropagation();
      e.preventDefault();
      doScrolling($target[0].offsetTop + 90, 300);
    }
  });

  $(document).on('change', '.crop-image-file-field', function(e) {
    if ($(this).val()) {
      pomagamy.show_popup($(this).data('url'));
    }
  });

  $('form#set-language-form select#id_language').change(e => {
    $(e.target)
      .parent('form')
      .submit();
  });

  $(document).on('click', '.js-show-hidden-content', function(e) {
    e.preventDefault();
    let $this = $(this);
    $page.find($this.data('target')).removeClass('hidden');
    $this.remove();
  });
};

$(document).ready(() => {
  // dom ready
  var elements = $('.sticky');
  Stickyfill.add(elements);
  initWaypoints();
  $(init);
});
