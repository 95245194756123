import { loadScript, removePaymentChannelTile } from '../tools';

const STRIPE_SCRIPT_URL = 'https://js.stripe.com/v3/';
const STRIPE_PAYMENT_METHOD_EVENT = 'paymentmethod';
let PAYMENT_REQUEST;

export function initStripePayments($form) {
  const load_stripe = $form.hasClass('js-allow-stripe-cc') || $form.hasClass('js-allow-stripe-google-pay');
  if (!load_stripe) {
    return;
  }

  const isMac = $('body').hasClass('mac'); // This class is set by the `checkMac` function called in app.js init.
  if (isMac) {
    // If the device is mac-like, we don't want to present Google Pay tile.
    onFailStripe();
    return;
  }

  // Load Stripe script only if device is other than mac-like.
  loadScript(STRIPE_SCRIPT_URL, onLoadStripe, onFailStripe);
}

function onLoadStripe() {
  window.pomagamy.stripe = Stripe(window.stripe_publishable_key, {
    locale: window.stripe_locale,
  });
  const testPaymentRequest = window.pomagamy.stripe.paymentRequest({
    country: 'PL',
    currency: 'pln',
    total: {
      label: 'validity_test',
      amount: 99,
    },
  });
  // Check the availability of the Payment Request API first.
  testPaymentRequest.canMakePayment().then(function(result) {
    if (!result || !result.googlePay) {
      onFailStripe();
    }
  });
}

function onFailStripe() {
  removePaymentChannelTile(window.stripe_gpay_channel);
}

function showError(msg) {
  $.growl({
    title: gettext('cmn-error'),
    message: msg,
  });
}
export function showGooglePayDialog(data) {
  // Shows payment flow based on a request stored in PAYMENT_REQUEST.
  // data - JsonResponse data from our backend
  const clientSecret = data.intent_client_secret;

  // Show actual Gpay dialog
  PAYMENT_REQUEST.show();

  PAYMENT_REQUEST.on(STRIPE_PAYMENT_METHOD_EVENT, function(ev) {
    // Confirm the PaymentIntent without handling potential next actions (yet).
    window.pomagamy.stripe
      .confirmCardPayment(
        clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false },
      )
      .then(function(confirmResult) {
        if (confirmResult.error) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          showError(data.error_msg);
          ev.complete('fail');
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete('success');
          // Check if the PaymentIntent requires any actions and if so let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11" instead
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (confirmResult.paymentIntent.status === 'requires_action') {
            // Let Stripe.js handle the rest of the payment flow.
            stripe.confirmCardPayment(clientSecret).then(function(result) {
              if (result.error) {
                // The payment failed -- ask your customer for a new payment method.
                showError(data.error_msg);
              } else {
                // The payment has succeeded
                window.location.href = data.success_url;
              }
            });
          } else {
            // The payment has succeeded.
            window.location.href = data.success_url;
          }
        }
      });
  });
}

export function createGooglePayPaymentRequest(value) {
  // Crates PaymentRequest and stores it in PAYMENT_REQUEST.
  if (typeof PAYMENT_REQUEST !== 'undefined' && PAYMENT_REQUEST !== null) {
    PAYMENT_REQUEST.off(STRIPE_PAYMENT_METHOD_EVENT);
  }
  PAYMENT_REQUEST = window.pomagamy.stripe.paymentRequest({
    country: 'PL',
    currency: 'pln',
    total: {
      label: 'Wpłata na Pomagam.pl',
      amount: value * 100,
    },
    requestPayerName: true,
    requestPayerEmail: true,
  });

  // Check the availability of the Payment Request API - it's required on
  // every new instance of paymentRequest.
  PAYMENT_REQUEST.canMakePayment().then(function(result) {
    if (!result || !result.googlePay) {
      // This shouldn't happen because it's been already checked during
      // presentation phase (see main.html scripts block)
      showError(gettext('cmn-error'));
    }
  });
}
