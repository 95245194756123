import { loadScript, removePaymentChannelTile } from '../tools';
import { pulseOnLoad } from '../../input_tools';
import { isMobileWidth } from '../../tools';

// An external script is used to generate fingerprintId required by ZEN.
// const SEON_SCRIPT_URL = 'https://cdn.deviceinf.com/js/v5/agent.js';
const ZEN_DATA_COLLECTOR_SCRIPT_URL = 'https://cdn-secure.zen.com/zen-web-collector.latest.js';
// An external script is used handle ZEN credit card checkout widget (popup).
const ZEN_CHECKOUT_SCRIPT_URL = 'https://cdn-secure.zen.com/zen-checkout.1.0.0.js';
const ZEN_SANDBOX_CHECKOUT_SCRIPT_URL = 'https://cdn-secure.zen-test.com/zen-checkout.1.0.0.js';
const ZEN_CARD_CHANNEL_ID_ATTR = 'data-zen-card-channel-id';
const ZEN_APPLE_PAY_CHANNEL_ID_ATTR = 'data-zen-apple-pay-channel-id';
const ZEN_APPLE_PAY_MERCHANT_ID_ATTR = 'data-zen-apple-pay-merchant-id';
const ZEN_AUTHORIZE_APPLE_PAY_URL_ATTR = 'data-zen-authorize-apple-pay-url';
const ZEN_EVENT_ENDPOINT_URL_ATTR = 'data-zen-event-endpoint-url';
const ZEN_APPLE_PAY_MANUAL_USER_DATA_INPUT_MODE = 'data-zen-apple-pay-manual-user-data-input-mode';
const APPLE_PAY_VERSION = 14;
const APPLE_PAY_SDK_URL = 'https://applepay.cdn-apple.com/jsapi/1.latest/apple-pay-sdk.js';
let ZenEventEndpointUrl;
let APPLE_PAY_SESSION;
let APPLE_PAY_SUPPORT;

export const widgetConfigParams = {
  boldFont: 'Roboto',
  regularFont: 'Roboto',
  overlayBackgroundsColor: 'rgba(0,0,0,0.5)',
  containerBackgroundColor: '#F9F9F9',
  containerBorderRadius: '10px',
  containerBorderColor: '1px solid #D3D4DC',
  containerBoxShadow: '0px 4px 34px 0px rgba(0, 0, 0, 0.25)',
  topBarBackgroundColor: '#FFFFFF',
  topBarFontColor: '#222222',
  topBarCloseIconColor: '#222222',
  inputBackgroundColor: '#FFFFFF',
  inputPrefixIconColor: '#e6e8eb',
  inputErrorColor: '#fb635f',
  inputFontColor: '#222222',
  inputPlaceholderFontColor: '#a8a8a8',
  inputBorderRadius: '5px',
  inputBorderColor: '1px solid #d3d4dc',
  inputBoxShadow: 'unset',
  buttonBackgroundColor: '#fb635f',
  buttonFontColor: '#FFFFFF',
  buttonBorderRadius: '5px',
  buttonBorderColor: 'unset',
  buttonBoxShadow: 'unset',
  fontAndIconColor: '#999999',
  statusSuccessIconColor: '#3fb77b',
  statusFailureIconColor: '#fb635f',
  statusPendingIconColor: '#3fb77b',
  statusTextColor: '#222222',
};

export function toggleOverlay(show) {
  // Shows/hides overlay with loading spinner for Apple Pay payments.
  $('.js-fullpage-overlay-backdrop').toggleClass('d-none', !show);
  $('.js-fullpage-loading-overlay').toggleClass('d-none', !show);
  $('body').toggleClass('modal-open', show);
}

function logZenEvent(support_id, event_type) {
  if (!ZenEventEndpointUrl) {
    console.log('Zen event endpoint URL is not set');
    return;
  }

  const payload = {
    support: '' + support_id,
    type: event_type,
    timestamp: new Date().toISOString(),
  };
  // SetTimeout is used to send async event to the backend
  setTimeout(function() {
    // console.log('Zen event:');
    // console.log(payload);
    // console.log('Endpoint:', ZenEventEndpointUrl);

    // No need to handle responses, just send the event data
    $.ajax({
      method: 'post',
      url: ZenEventEndpointUrl,
      data: payload,
    });
  }, 10);
}

export function initZenPayments($form, sandbox = false) {
  /*
   * ZEN is available for credit card / Apple Pay payment methods.
   * Current implementation uses a popup widget provided by ZEN for credit card payments.
   * FingerprintId and browserDetails are set, even though they are not strictly required by the checkout api call, yet
   * ZEN still requires them for fraud prevention.
   */
  const credit_cards_enabled = $form.hasClass('js-allow-zen-cc');
  const apple_pay_enabled = $form.hasClass('js-allow-zen-apple-pay');
  if (credit_cards_enabled || apple_pay_enabled) {
    loadScript(
      ZEN_DATA_COLLECTOR_SCRIPT_URL,
      function() {
        ZenEventEndpointUrl = $form.attr(ZEN_EVENT_ENDPOINT_URL_ATTR);

        initZenFields(apple_pay_enabled);
        if (credit_cards_enabled) {
          initZenWidgetEvents();
        }
      },
      onFailZen,
    );

    if ($form.is('.js-zen-sandbox-mode')) {
      loadScript(ZEN_SANDBOX_CHECKOUT_SCRIPT_URL, null, onFailZen);
    } else {
      loadScript(ZEN_CHECKOUT_SCRIPT_URL, null, onFailZen);
    }
  }
}

let scripts_failed_to_load = false;

function onFailZen() {
  // Prevent multiple calls
  if (scripts_failed_to_load) {
    return;
  }
  // Remove zen payment channel tile
  const $form = $('#payment-form');
  const cardChannelId = $form.attr(ZEN_CARD_CHANNEL_ID_ATTR);
  const applePayChannelId = $form.attr(ZEN_APPLE_PAY_CHANNEL_ID_ATTR);

  removePaymentChannelTile(cardChannelId);
  removePaymentChannelTile(applePayChannelId);
  scripts_failed_to_load = true;
}

function initZenWidgetEvents() {
  if (typeof getCollectorData === 'undefined') {
    onFailZen();
    return;
  }

  const $form = $('#payment-form');
  const $submitBtn = $form.find('#submit-btn');
  const loader_class = 'pulse-on-load';
  const cardChannelId = $form.attr(ZEN_CARD_CHANNEL_ID_ATTR);
  const applePayChannelId = $form.attr(ZEN_APPLE_PAY_CHANNEL_ID_ATTR);

  const optionsWithLoader = [cardChannelId, applePayChannelId];

  $form.find('.bank-option > label').on('click', function(e) {
    // Due to some internal shenanigans,
    // `.bank-option` click event triggers twice
    // with different targets (input and image).
    // This is a workaround to prevent double triggering of the event.
    // Additionally, remember to DO NOT directly bind on .bank-option element
    // because it's bigger than its visual representation.
    if (e.target.nodeName === 'INPUT') {
      return;
    }

    var $this = $(this);

    const paymentChannel = $this.find('input').attr('value');
    if (optionsWithLoader.includes(paymentChannel)) {
      $submitBtn.addClass(loader_class);
      pulseOnLoad($form);
    } else {
      // Handles the case when user chooses ZEN and switches to another channel
      // In such case, all stuff added by pulseOnLoad function needs to be removed
      if ($submitBtn.hasClass(loader_class)) {
        $submitBtn.removeClass(loader_class);
        $submitBtn.off('click.pulseOnLoad');
      }
      if ($submitBtn.parent().is('.load-button-wrapper')) {
        $submitBtn.unwrap('.load-button-wrapper');
      }
      if ($submitBtn.siblings('em.signal').length) {
        $submitBtn.siblings('em.signal').remove();
      }
    }
  });
}

function initZenFields(init_apple_pay) {
  // Double check, script may have loaded, but failed internally and not exposed seon.
  if (typeof getCollectorData === 'undefined') {
    onFailZen();
    return;
  }

  // Preemptively populate browser details form field.
  handleBrowserDetails();

  const form = document.getElementById('payment-form');

  if (init_apple_pay) {
    initApplePay();
  } else {
    removePaymentChannelTile(form.getAttribute(ZEN_APPLE_PAY_CHANNEL_ID_ATTR));
  }
}

function initApplePay() {
  const $form = $('#payment-form');
  const applePayChannelId = $form.attr(ZEN_APPLE_PAY_CHANNEL_ID_ATTR);
  const zenApplePayMerchantId = $form.attr(ZEN_APPLE_PAY_MERCHANT_ID_ATTR);

  // DEVS: Loading Apple Pay JS API sdk breaks Google Pay payments (probably due to Stripe JS multi-api wrapper).
  // It's crucial to ensure that this library is loaded only on devices that support Apple Pay.
  const isMac = $('body').hasClass('mac'); // This class is set by the `checkMac` function called in app.js init.
  if (!isMac) {
    removePaymentChannelTile(applePayChannelId);
    return;
  }

  function onApplePayLoad() {
    // Check if Apple Pay JS API library is installed and available - it binds to the window object.
    if (!window.ApplePaySession) {
      removePaymentChannelTile(applePayChannelId);
      return;
    }

    const is_mobile = isMobileWidth();
    const capabilities = ApplePaySession.applePayCapabilities(zenApplePayMerchantId);
    capabilities.then(capabilities => {
      const credentialStatus = capabilities.paymentCredentialStatus;
      if (is_mobile && credentialStatus === 'applePayUnsupported') {
        removePaymentChannelTile(applePayChannelId);
      } else if (!is_mobile && credentialStatus !== 'paymentCredentialsAvailable') {
        // DEVS: case on macOS on Safari, when Apple Pay is not available for some reason
        removePaymentChannelTile(applePayChannelId);
      }
    });
  }

  loadScript(APPLE_PAY_SDK_URL, onApplePayLoad, function() {
    removePaymentChannelTile(applePayChannelId);
  });
}

export function createApplePaySession(total_cost) {
  // Creates Apple Pay session and sets up event handlers.
  // https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/2320659-applepaysession

  const zenAuthorizeApplePayUrl = $('#payment-form').attr(ZEN_AUTHORIZE_APPLE_PAY_URL_ATTR);
  const $zenTxForm = $('#zen-tx-form');
  const manualUserDataMode = $('#payment-form').attr(ZEN_APPLE_PAY_MANUAL_USER_DATA_INPUT_MODE) === 'True';

  const paymentRequest = {
    countryCode: 'PL',
    currencyCode: 'PLN',
    supportedNetworks: ['visa', 'mastercard'],
    merchantCapabilities: ['supports3DS'],
    total: {
      label: 'Wpłata na Pomagam.pl',
      amount: total_cost,
    },
  };

  if (!manualUserDataMode) {
    paymentRequest.requiredShippingContactFields = ['email', 'name'];
  }

  APPLE_PAY_SESSION = new ApplePaySession(APPLE_PAY_VERSION, paymentRequest);

  // An event handler the system calls when it displays the payment sheet.
  // https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778021-onvalidatemerchant
  APPLE_PAY_SESSION.onvalidatemerchant = event => {
    $.ajax({ method: 'post', url: zenAuthorizeApplePayUrl })
      .done(data => {
        APPLE_PAY_SESSION.completeMerchantValidation(data);
      })
      .fail(data => {
        console.error('Error during Apple Pay merchant validation');
        cancelApplePaySession(true);
      });
  };

  APPLE_PAY_SESSION.oncancel = () => {
    cancelApplePaySession(false);
  };

  const onPaymentRequestSuccess = paymentResponse => {
    const token = JSON.stringify(paymentResponse.token);
    $zenTxForm.find('#id_payment_token').val(token);

    // Unlike BillingContact, ShippingContact is something that user can edit in Apple Pay dialog.
    // See: https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypayment/1916097-shippingcontact
    let billingName;
    let billingEmail;
    if (manualUserDataMode) {
      billingName = $('#payment-form').find('#id_name').val();
      billingEmail = $('#payment-form').find('#id_email').val();
    } else {
      const shippingContact = paymentResponse.shippingContact;
      billingName = shippingContact.givenName + ' ' + shippingContact.familyName;
      billingEmail = shippingContact.emailAddress;
    }
    $zenTxForm.find('#id_billing_name').val(billingName);
    $zenTxForm.find('#id_billing_email').val(billingEmail);

    // Get a fingerprint+browser details and submit ZEN tx form.
    // If fingerprint is already present, it will be used without creating a new one (if tx id matches).
    createZenTx(APPLE_PAY_SUPPORT.support_id, true, true);
  };

  // Called when the user has authorized the Apple Pay payment with Touch ID, Face ID, or a passcode.
  APPLE_PAY_SESSION.onpaymentauthorized = event => {
    const payment = event.payment;

    if (total_cost === parseFloat(APPLE_PAY_SUPPORT.value)) {
      onPaymentRequestSuccess(payment);
    } else {
      console.error('Apple Pay payment amount mismatch');
      cancelApplePaySession(true);
    }
  };
}

export function beginApplePaySession(tx_data) {
  // Shows Apple Pay payment dialog by using already present Apple Pay session
  // and stores transaction data validated by the backend for further client-side validation.
  // https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778001-begin
  if (APPLE_PAY_SESSION) {
    APPLE_PAY_SUPPORT = {
      support_id: tx_data.sid,
      value: tx_data.value,
    };

    // Optimization: populate ZEN tx form with fingerprint and browser data, without submitting it.
    createZenTx(APPLE_PAY_SUPPORT.support_id, true, false);

    APPLE_PAY_SESSION.begin();
  }
}

export function cancelApplePaySession(abort = false, display_growl = true) {
  // Resets Apple Pay session and aborts it if requested. Presents a notification to the user.
  if (abort) {
    APPLE_PAY_SESSION.abort();
  }
  APPLE_PAY_SESSION = null;
  APPLE_PAY_SUPPORT = null;

  if (display_growl) {
    $.growl({
      title: gettext('cmn-error'),
      message: gettext('supwiz-failed-or-canceled-payment'),
    });
  }
  toggleOverlay(false);
  if ($.fn.stopPulseLoading) {
    $.fn.stopPulseLoading();
  }
}

function handleBrowserDetails() {
  // Populates browser details form field with data when not present and returns the data.
  // Browser details are required by Zen for fraud prevention
  const detailsElem = document.getElementById('id_browser_details');
  let browserDetails = detailsElem.value;
  if (!browserDetails) {
    const paymentForm = document.getElementById('payment-form');
    let browserDetails = {
      acceptHeader: decodeURIComponent(paymentForm.getAttribute('data-accept-header')),
      colorDepth: window.screen.colorDepth,
      javaEnabled: window.navigator.javaEnabled(),
      lang: decodeURIComponent(paymentForm.getAttribute('data-language')),
      screenHeight: window.screen.height,
      screenWidth: window.screen.width,
      timezone: new Date().getTimezoneOffset(),
      windowSize: '05',
      userAgent: decodeURIComponent(paymentForm.getAttribute('data-user-agent')),
    };
    browserDetails = JSON.stringify(browserDetails);
    detailsElem.value = browserDetails;
  }
  return browserDetails;
}

export function createZenTx(merchantTransactionId, isApplePay = false, submit = false) {
  /*
  Handles 2 main cases:
  - prefill the form with fingerprintId and browserDetails (if submit=false)
  - submit the form (if submit=true)
  Ensures that the fingerprintId is generated for the current transaction.
  */

  function _cleanup() {
    // Clear ApplePay-specific form fields after submission
    ['id_payment_token', 'id_billing_name', 'id_billing_email'].forEach(fieldId => {
      document.getElementById(fieldId).value = '';
    });
  }

  function _directSubmit() {
    // Show overlay with loading spinner until backend successfully creates ZEN transaction.
    toggleOverlay(true);

    // Manually handle ajax form submission (instead of the usual form.ajax handler).
    // Used in conjunction with UltimateAjaxMixin.
    const $form = $('#zen-tx-form');
    $.ajax({
      method: 'post',
      url: $form.attr('action'),
      data: $form.serializeArray(),
    })
      .done(function(data, status, xhr) {
        APPLE_PAY_SESSION.completePayment(ApplePaySession.STATUS_SUCCESS);
        window.location.href = data.callback_data.apple_pay.success_url;
      })
      .fail(function(data) {
        APPLE_PAY_SESSION.completePayment(ApplePaySession.STATUS_FAILURE);
        cancelApplePaySession(false, true);
      })
      .always(function() {
        _cleanup();
      });
  }

  function _submit() {
    if (isApplePay) {
      // Apple Pay transactions are handled differently, by a direct ajax submit.
      logZenEvent(merchantTransactionId, 'zen_tx_submit');
      _directSubmit();
      return;
    }

    $('#zen-tx-form').submit(); // DON'T refactor into pure JS, it breaks the form submission handlers.
    _cleanup();
  }

  const FORM_FINGERPRINT_TX_ID_ATTR = 'data-zen-fingerprint-tx-id';
  const paymentForm = document.getElementById('payment-form');
  const browserDetails = handleBrowserDetails(); // Ensure browser details are set - redundant in 99.9% of cases.
  merchantTransactionId = '' + merchantTransactionId; // Ensure it's a string (required by the collector function).

  if (
    submit === true &&
    document.getElementById('id_fingerprint').value &&
    browserDetails &&
    // extra check to ensure that fingerprint was generated for the current transaction
    paymentForm.getAttribute(FORM_FINGERPRINT_TX_ID_ATTR) === merchantTransactionId
  ) {
    _submit();
    return;
  }

  if (typeof getCollectorData === 'undefined') {
    $.growl.error({
      title: gettext('cmn-error'),
      message: gettext('form-err-sth-went-wrong'),
    });
    return;
  }

  if (isApplePay) logZenEvent(merchantTransactionId, 'zen_tx_init');

  const collectorData = getCollectorData(merchantTransactionId);
  collectorData.then(fingerPrintId => {
    document.getElementById('id_fingerprint').value = fingerPrintId;
    // Fingerprint is created for a specific transaction, so its transaction id is stored in the form.
    paymentForm.setAttribute(FORM_FINGERPRINT_TX_ID_ATTR, merchantTransactionId);
    if (submit === true) {
      _submit();
    }
  });
}
