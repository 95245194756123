import { inputIntValue, localizedFormatMoney } from '../tools';
import { validateDonation } from './tools';

export function initExp47VariantB($form, variant_data, update_payment_summary_func) {
  const DONATION_STEP = 2;
  const CHANNEL_AND_SUMMARY_STEP = 3;

  const $pledge = $form.find('#id_pledge');
  const $donation = $form.find('#id_donation');
  const $donation_helper = $form.find('#id_donation_helper');

  // Variant B (1) introduces a 3-step support process:
  // - Step 1 (visible by default): pledge amount selection, shows step 2.
  // - Step 2 (initially hidden): donation selection, shows step 3.
  // - Step 3 (initially hidden): payment channel selection and user data input.
  const $extraDonationsSection = $form.find('.js-zero-donation-section');
  const $extraDonationsWrapper = $form.find('.js-donation-preset-extra-wrapper');
  let isCustomDonationPanelVisible = false;

  $donation_helper.on('change keyup', function() {
    $form.find(`.step-${CHANNEL_AND_SUMMARY_STEP}-container`).removeClass('hidden');
  });

  function clearVariantBDonationSelections() {
    // Nullifies current values and forces user to set donation again.
    $donation.val(null);
    $donation_helper.val(null);
    $form.find('.js-donation-btn').removeClass('selected');
  }

  function renderDonationValues(pledge_value) {
    // Renders donation values based on pledge value and variant data.
    function calculateDonationValues(percentages, pledgeValue) {
      return percentages.map(percentage => ({
        percentage: percentage,
        value: Math.round(pledgeValue * percentage) / 100,
      }));
    }

    const donation_main_values = calculateDonationValues(variant_data.donation_main_percentages, pledge_value);
    const donation_extra_values = calculateDonationValues(variant_data.donation_extra_percentages, pledge_value);

    // Fill main donation values.
    for (const { percentage, value } of donation_main_values) {
      const $presetElem = $form.find(`.js-donation-preset[data-percentage="${percentage}"]`);
      $presetElem.data('value', value);
      $presetElem.find('.js-donation-preset-value').text(`(${localizedFormatMoney(value, 2)} zł)`);
    }

    // Fill extra donation values.
    $extraDonationsWrapper.html('');
    for (const { percentage, value } of donation_extra_values) {
      const $newElement = $(`
        <div class="donation-preset js-donation-preset" 
             data-percentage="${percentage}" data-value="${value}">
          <div class="donation-btn donation-btn-extra js-donation-btn">
            <div class="preset-value">
              <span class="bigger">${percentage}%</span>&nbsp;(${localizedFormatMoney(value, 2)}&nbsp;zł)
            </div>
          </div>
        </div>
      `);
      $extraDonationsWrapper.append($newElement);
    }
  }

  $form.on('click', '.js-donation-btn', function() {
    const $this = $(this);
    const donationValue = parseFloat($this.parent().data('value'));
    const donationPercentage = parseFloat($this.parent().data('percentage'));
    // Choosing 0 donation should show extra donations section
    // Choosing anything from extra section should not hide extra donations section
    const toggleExtraDonations =
      donationPercentage !== 0 && !variant_data.donation_extra_percentages.includes(donationPercentage);

    clearVariantBDonationSelections();

    $this.addClass('selected');
    $form.find(`.step-${CHANNEL_AND_SUMMARY_STEP}-container`).removeClass('hidden');
    $donation.val(donationValue);
    $extraDonationsSection.toggleClass('hidden', toggleExtraDonations);

    // Hide custom donation panel if was visible on donation change
    if (isCustomDonationPanelVisible) {
      isCustomDonationPanelVisible = false;
      toggleCustomDonationPanel(false);
    }
    update_payment_summary_func();
  });

  // Foundation (and some other projects) have disabled donations.
  const stepAfterPledgeSelect = $form.is('.js-hide-donation') ? CHANNEL_AND_SUMMARY_STEP : DONATION_STEP;
  $pledge.on('change', function() {
    clearVariantBDonationSelections();
    renderDonationValues(inputIntValue($pledge));
    $form.find(`.step-${stepAfterPledgeSelect}-container`).removeClass('hidden');
    // Hide custom donation panel if was visible on pledge change
    if (isCustomDonationPanelVisible) {
      isCustomDonationPanelVisible = false;
      toggleCustomDonationPanel(false);
    }
  });

  function toggleCustomDonationPanel(show) {
    $form.find('.js-donation-panel').toggleClass('hidden', !show);
    $form.find('.js-toggle-donation-panel[data-show="false"]').toggleClass('hidden', !show);
    $form.find('.js-toggle-donation-panel[data-show="true"]').toggleClass('hidden', show);
  }

  // This is originally handled in createSlider function.
  // Variant B is not using slider at all so it's needed to be handled here.
  $form.find('.js-toggle-donation-panel').on('click', function(e) {
    e.preventDefault();
    isCustomDonationPanelVisible = !isCustomDonationPanelVisible;
    toggleCustomDonationPanel(isCustomDonationPanelVisible);
    clearVariantBDonationSelections();
    update_payment_summary_func();
  });
}

export function updateVariantBDonationValue(pledge_value, $form) {
  const $donation = $form.find('#id_donation');
  const $custom_donation_helper = $form.find('#id_donation_helper');

  let donation_value = 0;
  if ($custom_donation_helper.is(':visible')) {
    let custom_donation_helper_value = $custom_donation_helper.val();
    donation_value = parseInt(custom_donation_helper_value);
    $donation.val(donation_value);
  } else {
    donation_value = parseFloat($donation.val());
  }
  // Donation = 0 should also be validated (case when user changes error donation to 0 doesn't remove error field)
  validateDonation(pledge_value, donation_value, $form);
  return donation_value;
}
