import {
  getCookie,
  isMobileWidth,
  localizedFormatMoney,
  push_enhanced_ecommerce_data,
} from '../tools';

export function recentSupportNotification($page) {
  const $endpoint = $page.find('#support-endpoint');
  const support_id = getCookie($endpoint.data('key'));
  if (!support_id) {
    return;
  }
  const endpoint_url = $endpoint.data('target');
  const intervals = [5, 10, 15, 20, 30, 30];
  const timer = ms => new Promise(res => setTimeout(res, ms));

  function showMessageBar(selector) {
    const $message_bar = $page.find('.message-bar');
    if (!$message_bar.length) {
      return;  // ProjectDetails page doesn't support message bars.
    }
    const $msg = $message_bar.find(selector);
    const hiding_class = 'd-none';

    $message_bar.attr('class', 'message-bar').addClass($msg.data('color'));
    $message_bar.find('.js-box-message').addClass(hiding_class);
    $msg.removeClass(hiding_class);
  }

  function showError(selector, error_message) {
    showMessageBar(selector);

    $.growl.error({
      title: gettext('cmn-error'),
      message: error_message,
    });
  }

  async function getStatus() {
    let finish = false;
    for (const interval of intervals) {
      if (finish) {
        break;
      }
      $.get(endpoint_url)
        .done(function(data) {
          const status = data.status;
          if (status === 'PENDING') {
            // Do nothing, let's wait some more
          } else if (status === 'PAID') {
            showMessageBar('.js-paid');
            $.growl({
              title: gettext('notif-title-ready'),
              message: gettext('cmn-support-booked'),
            });
            finish = true;
          } else if (status === 'FAILED') {
            showError('.js-failed', gettext('supwiz-failed-or-canceled-payment'),
            );
            finish = true;
          } else {
            showError('.js-error', gettext('form-err-sth-went-wrong'));
            finish = true;
          }
        })
        .fail(function() {
          showError('.js-error', gettext('form-err-sth-went-wrong'));
          finish = true;
        });
      await timer(interval * 1000);
    }
  }
  getStatus();
}

export function push_GA_details_event() {
  push_enhanced_ecommerce_data(
    'detail',
    pomagamy.ec_data,
    {},
    'Product Details',
    true,
  );
}

export function push_GA_checkout_event($eventForm, step, value, description) {
  let data = { step: step };
  if (value !== undefined) {
    data['value'] = value;
  }
  let checkoutSteps = {
    1: () => $eventForm.find('#id_pledge').val(),
    2: () => $eventForm.find('input[name=payment_channel]:checked').val(),
  };
  let previousSteps = [];
  Object.entries(checkoutSteps).forEach(([key, func]) => {
    let keyNum = parseInt(key); // js parses keys to strings
    if (keyNum !== step) {
      const value = func();
      previousSteps.push({ step: keyNum, value: value !== undefined ? value : null });
    }
  });
  push_enhanced_ecommerce_data(
    'checkout',
    pomagamy.ec_data,
    data,
    description,
    false,
    { previousSteps: previousSteps },
  );
}

export function push_GA_add_event() {
  push_enhanced_ecommerce_data(
    'add',
    pomagamy.ec_data,
    {},
    'Product Add To Cart',
    false,
  );
}

export function storeGAid($elem) {
  // Let's get GA clientID and pass it to backend.
  try {
    var tracker = ga.getAll()[0];
    $elem.val(tracker.get('clientId'));
  } catch (e) {
    // whatever, pass
  }
}

export function hideOddMobilePaymentChannel($form) {
  // Hide odd payment channel on mobile.
  if (isMobileWidth() && $form.find('.bank-option:not(.hidden)').length % 2) {
    $form.find('.bank-option:not(.hidden):last').addClass('hidden');
  }
}

export function initHideUserData($form) {
  // Set data hider to OFF on init by default.
  // Exception: in support restore mode keep the source value.
  // Unused now, but let's keep support restore feature for the future use
  const $hide_user_data = $form.find('#id_hide_name');
  if ($hide_user_data.is(':checked') && !isSupportRestoreMode($form)) {
    $hide_user_data.click();
  }
}

export function isSupportRestoreMode($form) {
  // Support restore mode: form is prefilled with support's values
  // Partially used now by FundacjaPomagam upsell,
  // but all Support fields are supported in js.
  return $form.hasClass('js-restore-mode');
}

export function calculateRates(pledge_value, donation_value, $form) {
  // Updates exchange rates on international payment pages.
  const rates = window.exchange_rates;
  if (!rates.length) {
    return;
  }

  // custom pledge
  const $intro_rates = $form.find('.js-intro-rate');
  const $rate_elems = $form.find('.js-rate');
  const hidden_cls = 'hidden';
  if (pledge_value) {
    $intro_rates.addClass(hidden_cls);
    $rate_elems.removeClass(hidden_cls);
  } else {
    $intro_rates.removeClass(hidden_cls);
    $rate_elems.addClass(hidden_cls);
  }

  // payment summary
  function setRateValue(field_id_name, rate_code, raw_value) {
    $form
      .find(`${field_id_name}_${rate_code} .js-est`)
      .text(localizedFormatMoney(raw_value, 2));
  }

  let pledge_est, total_est;
  rates.forEach(rate_data => {
    pledge_est = pledge_value / rate_data.rate;
    setRateValue('#pledge', rate_data.code, pledge_est);
    total_est = (pledge_value + donation_value) / rate_data.rate;
    setRateValue('#total', rate_data.code, total_est);
  });
}

export function updateDonationValue(pledge_value, slider, $form) {
  // Sets donation value
  // from a helper (input field / slider) to a real hidden field
  // and returns said value
  // Also validates donation value (doesn't affect returned value).
  const $custom_donation_helper = $form.find('#id_donation_helper');
  let donation_value = 0;
  if (!$form.is('.js-hide-donation')) {
    if ($custom_donation_helper.is(':visible')) {
      var custom_donation_helper_value = $custom_donation_helper.val();
      if (custom_donation_helper_value) {
        donation_value = parseInt(custom_donation_helper_value);
      }
    } else if (slider) {
      donation_value = (slider.noUiSlider.get() / 100) * pledge_value;
      // rounds to 2 decimal places.
      donation_value = Math.round((donation_value + Number.EPSILON) * 100) / 100;
    }
  }
  $form.find('#id_donation').val(donation_value);
  // Donation = 0 should also be validated (case when user changes error donation to 0 doesn't remove error field)
  validateDonation(pledge_value, donation_value, $form);
  return donation_value;
}

export function validateDonation(pledge_value, donation_value, $form) {
  const maxDonationPercentage = parseInt($form.attr('data-max-donation-percentage'));
  const errorConditions = {
    donationAmount: {
      isActive: false,
      message: gettext('supwiz-form-err-donation-amount'),
    },
    donationThreshold: {
      isActive: false,
      message: (maxValue, currency) =>
        interpolate(
          gettext('supwiz-form-err-donation-threshold %s %s %s'),
          [
            maxDonationPercentage,
            localizedFormatMoney(maxValue, 2),
            currency
          ]
        )
    },
  };
  const $donation_helper = $form.find('#id_donation_helper');
  const $donationErrorWrapper = $form.find('#donation-error-wrapper');
  const min = parseInt($donation_helper.attr('min'), 10);
  const max = parseInt($donation_helper.attr('max'), 10);

  function addFieldError(message) {
    $donationErrorWrapper.addClass('error');
    $donationErrorWrapper.find('.donation-error').remove(); // remove previous error messages
    $donationErrorWrapper.append(`<span class='error-msg donation-error'>` + message + '</span>');
  }
  function removeFieldErrors() {
    $donationErrorWrapper.removeClass('error');
    $donationErrorWrapper.find('.donation-error').remove();
  }

  function checkDonationAmount() {
    if (donation_value < min || donation_value > max) {
      if (!errorConditions.donationAmount.isActive) {
        errorConditions.donationAmount.isActive = true;
        addFieldError(errorConditions.donationAmount.message);
      }
    } else {
      errorConditions.donationAmount.isActive = false;
    }
  }

  function donationExceedsThreshold(pledge, donation, maximumDonationPercentage = 0.3) {
    const maxAllowedDonation = (pledge * maximumDonationPercentage).toFixed(2);
    return [donation > maxAllowedDonation, parseFloat(maxAllowedDonation)];
  }

  function checkDonationPercentage() {
    const donation_fraction = maxDonationPercentage / 100;
    const [donation_exceeded, max_allowed_donation] = donationExceedsThreshold(
      pledge_value,
      donation_value,
      donation_fraction,
    );
    if (donation_exceeded) {
      if (!errorConditions.donationThreshold.isActive) {
        errorConditions.donationThreshold.isActive = true;
        addFieldError(errorConditions.donationThreshold.message(max_allowed_donation, gettext('cmn-currency-symbol')));
      }
    } else {
      errorConditions.donationThreshold.isActive = false;
    }
  }

  // Order of those checks is important in order to be consistent with the backend (double validation).
  checkDonationAmount();
  if (!errorConditions.donationAmount.isActive) {
    removeFieldErrors();
    // removing all field errors means that the second error is no longer active.
    errorConditions.donationThreshold.isActive = false;

    checkDonationPercentage();
    if (!errorConditions.donationThreshold.isActive) {
      removeFieldErrors();
    }
  }
}

export function updateSummaryValues(pledge_val, donation_val, $form) {
  // Updates payment summary values (pledge, donation, total)
  function setValue(selector, value, rounding) {
    $form.find(selector).text(localizedFormatMoney(value, rounding));
  }
  setValue('#checkout-pledge', pledge_val, 0);
  setValue('#checkout-community-pledge', donation_val, 2);
  setValue('#checkout-total', pledge_val + donation_val, 2);
}

export function loadScript(url, callback, fail_callback) {
  jQuery
    .ajax({
      url: url,
      dataType: 'script',
      async: true,
    })
    .done(function(script, textStatus) {
      if (typeof callback === 'function') {
        callback();
      }
    })
    .fail(function(jqxhr, settings, exception) {
      if (typeof fail_callback === 'function') {
        fail_callback();
      }
    });
}

export function removePaymentChannelTile(channelId) {
  const $form = $('#payment-form');
  const removed_elems = $form
    .find(`.bank-list input[value=${channelId}]`)
    .closest('.bank-option')
    .remove();
  // backfill one option, so the total number of initially shown options is right
  if (removed_elems.length) {
    $form
      .find('.bank-option.hidden')
      .first()
      .removeClass('hidden');
  }
}
